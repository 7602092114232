import React from "react";

import { useFirebase } from "../../FirebaseContext";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const { DateTime } = require("luxon");

const DeletedEvents = ({
  deletedEvents,
  isMobile,
  calendarRef,
  setShowSidebar,
  setShowWeekends,
  showWeekends,
}) => {
  const sidebarButtonStyle = {
    margin: 5,
  };
  const closeButtonStyle = {
    position: "absolute",
    top: 75,
    left: 15,
  };

  return (
    <div
      style={{
        marginTop: 130,
        width: isMobile ? "100vw" : "75vw",
        maxWidth: 800,
      }}
    >
      <div style={closeButtonStyle}>
        <IconButton
          onClick={() => {
            setShowSidebar(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: isMobile ? "flex" : "none",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span>
          <Button
            style={sidebarButtonStyle}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().today();
              setShowSidebar(false);
              console.log(calendarRef.current.getApi());
            }}
          >
            today
          </Button>
          <Button
            style={sidebarButtonStyle}
            variant="contained"
            onClick={() => {
              setShowWeekends(!showWeekends);
              setShowSidebar(false);
            }}
          >
            weekends
          </Button>
        </span>
        <span style={{ marginTop: 20 }}>
          <Button
            style={sidebarButtonStyle}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("dayGridMonth");
              setShowSidebar(false);
            }}
          >
            month
          </Button>
          <Button
            style={sidebarButtonStyle}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("timeGridWeek");
              setShowSidebar(false);
            }}
          >
            week
          </Button>
          <Button
            style={sidebarButtonStyle}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("timeGridDay");
              setShowSidebar(false);
            }}
          >
            day
          </Button>
        </span>
      </div>
      <div id="external-events">
        {deletedEvents &&
          deletedEvents.map((event) => (
            <DeletedEvent key={event.id} event={event} />
          ))}
      </div>
    </div>
  );
};

export default DeletedEvents;

const DeletedEvent = ({ event }) => {
  const firebase = useFirebase();

  const start = DateTime.fromISO(event.start).setZone("Australia/Sydney");
  const locale = start.toLocaleString(DateTime.DATE_MED);

  const restoreEvent = async () => {
    const eventDataRef = await firebase.getDoc(
      firebase.doc(firebase.db, "deletedEvents", event.id)
    );
    const eventData = eventDataRef.data();
    await firebase.addDoc(
      firebase.collection(firebase.db, "events"),
      eventData
    );
    await firebase.deleteDoc(
      firebase.doc(firebase.db, "deletedEvents", event.id)
    );
  };

  const deleteEvent = async () => {
    await firebase.deleteDoc(
      firebase.doc(firebase.db, "deletedEvents", event.id)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 8,
        marginBottom: 8,
      }}
    >
      <IconButton style={{ marginLeft: 10 }} onClick={restoreEvent}>
        <RestoreFromTrashIcon />
      </IconButton>
      <span>
        {locale} {event.title ? "- " : null}
        {event.title}
      </span>
      <IconButton style={{ marginRight: 10 }} onClick={deleteEvent}>
        <DeleteForeverIcon />
      </IconButton>
    </div>
  );
};
