import React, { useState, useEffect } from "react";

import Card from './Card'

//Imports custom firebase hook, exposing firebase functions and states
// import { useFirebase } from '../FirebaseContext'
//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from '../../ThemeContext';


const List = ({ list }) => {
    //Declares custom firebase variable
    // const firebase = useFirebase()
    //Declares custom theme variable
    const theme = useTheme()

    const [cards, setCards] = useState([])

    useEffect(() => {
        setCards([...list.jobs])
    }, [list, setCards])

    const listStyle = {
        background: theme.darkModeValues.darkModeDrawerBackground,
        flexShrink: 0,
        width: 272,
        height: 'fit-content',
        margin: 6,
        marginRight: 0,
        borderRadius: 5,
        border: '1px solid rgba(0, 0, 0, 0.12)',

    }
    return (
        <div style={listStyle}>
            {list.title}
            {
                cards && cards.map((card, index) => <Card key={`${card}=${index}`} card={card} />)
            }
        </div>
    )
}

export default List
