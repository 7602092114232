import React, { useState, useEffect } from "react";

import Board from './kanban/Board'

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from '../FirebaseContext'
//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from '../ThemeContext';

// import initialData from './kanban/initialData';

// console.log(initialData)

const Kanban = () => {
    //Declares custom firebase variable
    const firebase = useFirebase()
    //Declares custom theme variable
    const theme = useTheme()

    const [boards, setBoards] = useState([])

    useEffect(() => {
        const boardsQuery = firebase.query(firebase.collection(firebase.db, "boards"))
        const unsub = firebase.onSnapshot(boardsQuery, (querySnapshot) => {
            const tmpBoards = [];
            querySnapshot.forEach((doc) => {
                tmpBoards.push(
                    {
                        id: doc.id,
                        ...doc.data()
                    }
                );
            });
            setBoards([...tmpBoards])
        });
        return (() => unsub())
    }, [firebase, setBoards])

    return (
        <div style={{
            color: theme.isDarkMode ? theme.darkModeValues.darkModeTextColour : theme.darkModeValues.lightModeTextColour,
            width: '100%',
        }}>
            {/* Kanban */}
            {
                boards && boards.map((board, index) => <Board key={`${board}=${index}`} board={board} />)
            }
            {/* {boardKeys.map((board, index) => <Board key={`${board}=${index}`} board={initialData.boards[board]} tasks={initialData.tasks} />)} */}
        </div>
    )
}

export default Kanban
