import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import EventDataTab from "./EventDataTab";
import InvoiceDataTab from "./InvoiceDataTab";

const EventDrawer = ({
  firebase,
  isMobile,
  setShowEventModal,
  setLayoutMenuDisabled,
  eventTypes,
  eventData,
  setEventData,
  deleteEvent,
  clientAutoFill,
}) => {
  // console.log(eventData?.event?.extendedProps?.notes)

  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleMenuClick = (e) => {
    setMenuAnchor(e.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const closeButtonStyle = {
    position: "absolute",
    top: 75,
    right: 15,
    zIndex: 1000,
  };
  const menuButtonStyle = {
    position: "absolute",
    top: 75,
    left: 15,
    zIndex: 1000,
  };
  const mainStyle = {
    display: "flex",
    flexDirection: "column",
  };
  const tabsContainerStyle = {
    position: "absolute",
    top: 75,
    display: "flex",
    justifyContent: "center",
    marginLeft: -40,
    width: isMobile ? "100vw" : "75vw",
    maxWidth: 800,
  };
  const [tabValue, setTabValue] = useState("Event");

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabStyle = {
    minWidth: 120,
    maxWidth: 140,
  };
  return (
    <div
      style={{
        marginTop: 80,
        padding: 40,
        width: isMobile ? "100vw" : "75vw",
        maxWidth: 800,
      }}
    >
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={deleteEvent}>Delete Event</MenuItem>
      </Menu>
      <div style={closeButtonStyle}>
        <IconButton
          onClick={() => {
            setShowEventModal(false);
            setLayoutMenuDisabled(false);
            setEventData({});
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={menuButtonStyle}>
        <IconButton onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
      </div>
      <div style={tabsContainerStyle}>
        <Tabs value={tabValue} onChange={handleTabValueChange}>
          <Tab label="Event" sx={tabStyle} value="Event" />
          <Tab label="Invoice" sx={tabStyle} value="Invoice" />
        </Tabs>
      </div>
      <TabPanel value={tabValue} index={"Event"}>
        <div style={mainStyle}>
          <EventDataTab
            firebase={firebase}
            eventData={eventData}
            eventTypes={eventTypes}
            isMobile={isMobile}
          />
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={"Invoice"}>
        <div style={mainStyle}>
          <InvoiceDataTab
            firebase={firebase}
            eventData={eventData}
            isMobile={isMobile}
            clientAutoFill={clientAutoFill}
          />
        </div>
      </TabPanel>
    </div>
  );
};

export default EventDrawer;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
