import React, { useState, useEffect } from "react";

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from '../../FirebaseContext'
//Imports custom theme hook, exposing custom theme functions and states
// import { useTheme } from '../../ThemeContext';


const Card = ({ card }) => {
    //Declares custom firebase variable
    const firebase = useFirebase()
    //Declares custom theme variable
    // const theme = useTheme()

    const [data, setData] = useState({})
    console.log(data)

    useEffect(() => {
        const unsub = firebase.onSnapshot(firebase.doc(firebase.db, "jobs", card), (doc) => {
            const id = doc.id
            const data = doc.data()
            setData({ ...data, id })
        });
        return () => unsub()
    }, [card, setData, firebase])

    return (
        <div>
            {data.title}
        </div>
    )
}

export default Card
