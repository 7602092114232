import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import ScaleText from "react-scale-text";

import { useFirebase } from "../../FirebaseContext";
import { useTheme } from "../../ThemeContext";
import { useWebKit } from "../../WebKitContext";

// import { encode } from "base64-arraybuffer";
// const { Fido2Lib } = require("fido2-lib");

// const f2l = new Fido2Lib({
//   timeout: 42,
//   rpName: "https://videsk.morrisseymedia.com.au",
//   rpIcon:
//     "https://firebasestorage.googleapis.com/v0/b/videsk-system.appspot.com/o/logo%2Flogo.png?alt=media&token=2057e90c-10c1-4d80-b5e5-30fb063be9c3",
//   challengeSize: 128,
//   attestation: "direct",
//   cryptoParams: [-7],
//   authenticatorAttachment: "platform",
//   authenticatorRequireResidentKey: false,
//   authenticatorUserVerification: "required",
// });

const UserCard = ({ user, setLoadingVisible }) => {
  const firebase = useFirebase();
  const theme = useTheme();
  const WebKit = useWebKit();

  const classes = makeStyles({
    avatarContainerStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      padding: "10px",
      margin: "5px",
      borderRadius: "10px",
      color: theme.isDarkMode
        ? "#e5e5e5"
        : theme.darkModeValues.darkModeBackground,
      width: 140,
      height: 180,
      position: "relative",
      userSelect: "none",
      backgroundColor: theme.isDarkMode ? "#ffffff35" : "#00000015",
      "&:hover": {
        backgroundColor: theme.isDarkMode ? "#ffffff25" : "#00000025",
      },
    },
    avatarContainerStyleModal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      padding: "10px",
      margin: "0px",
      borderRadius: "10px",
      color: theme.isDarkMode
        ? "#e5e5e5"
        : theme.darkModeValues.darkModeBackground,
      width: 140,
      height: 170,
      backgroundColor: theme.isDarkMode ? "#ffffff35" : "#00000015",
      userSelect: "none",
    },
    textColour: {
      color: theme.sDarkMode
        ? "#e5e5e5"
        : theme.darkModeValues.darkModeBackground,
    },
    textFieldMulti: {
      '& [class*="MuiInputLabel-root"], & [class*="MuiOutlinedInput"], & [class*="Mui-focused"],':
        {
          color: theme.isDarkMode ? "#e5e5e5 !important" : ``,
          borderColor: theme.isDarkMode ? "#e5e5e5 !important" : ``,
        },
    },
  })();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setPasswordless(user.passwordless);
    setOpen(true);
  };
  const handleClose = () => {
    setPasswordless(user.passwordless);
    setOpen(false);
  };

  const [password, setPassword] = useState("");
  const [passwordLabelText, setPasswordLabelText] = useState("Password");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordless, setPasswordless] = useState(user.passwordless);

  useEffect(() => {
    if (WebKit.webKitAvailable) {
      window.webkitOnMessage = (arg) => {
        console.log(arg);
      };
      window.webkitAuthSuccess = (arg) => {
        console.log(arg);
        handleClose();
      };
      window.webkitAuthError = (arg) => {
        console.log(arg);
        handleClose();
      };
    }
    // eslint-disable-next-line
  }, [WebKit.webKitAvailable]);

  useEffect(() => {
    setPasswordless(user.passwordless);
  }, [user]);

  const [showLoginButton, setShowLoginButton] = useState(true);
  const [showForgotPasswordButton, setShowForgotPasswordButton] =
    useState(true);

  const [showPasswordResetSent, setShowPasswordResetSent] = useState(false);
  const handleClick = async () => {
    if (!user.email) {
      return;
    }
    handleOpen();
    // if (passwordless) {
    //   // if (WebKit.webKitAvailable) {
    //   //   window.webkit.messageHandlers.bridge.postMessage("authenticate");
    //   //   return;
    //   // }
    //   try {
    //     if (
    //       !(await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable())
    //     ) {
    //       setPasswordless(false);
    //       return;
    //     }
    //   } catch (e) {
    //     setPasswordless(false);
    //     return;
    //   }
    //   const authnOptions = await f2l.assertionOptions();
    //   const buf = Buffer.from(authnOptions.challenge);
    //   const challenge = new Uint8Array(buf);
    //   const options = {
    //     publicKey: {
    //       challenge: challenge,
    //     },
    //   };
    //   const clientAssertionResponse = await navigator.credentials
    //     .get(options)
    //     .catch((e) => console.log(e));
    //   if (!clientAssertionResponse) {
    //     setPasswordless(false);
    //     return;
    //   }

    //   const clientAssertionResponseParsed = {
    //     authenticatorAttachment:
    //       clientAssertionResponse.authenticatorAttachment,
    //     id: clientAssertionResponse.id,
    //     rawId: encode(clientAssertionResponse.rawId),
    //     response: {
    //       authenticatorData: encode(
    //         clientAssertionResponse.response.authenticatorData
    //       ),
    //       clientDataJSON: encode(
    //         clientAssertionResponse.response.clientDataJSON
    //       ),
    //       signature: encode(clientAssertionResponse.response.signature),
    //       userHandle: encode(clientAssertionResponse.response.userHandle),
    //     },
    //     type: clientAssertionResponse.type,
    //   };
    //   setLoadingVisible(true);
    //   const userToken = await firebase
    //     .getAuthToken({
    //       uid: user.uid,
    //       clientAssertionResponse: clientAssertionResponseParsed,
    //       challenge: encode(authnOptions.challenge),
    //       origin: window.location.href.replace(/\[^\/]*$/, ""),
    //     })
    //     .catch((e) => {
    //       setLoadingVisible(false);
    //     });

    //   if (userToken.data.error) {
    //     setPasswordless(false);
    //     setLoadingVisible(false);
    //     return;
    //   }
    //   await firebase.signInWithCustomToken(firebase.auth, userToken.data);
    //   setLoadingVisible(false);
    // }
  };

  const handleLogin = () => {
    if (!password) {
      setPasswordError(true);
      return;
    }
    setShowLoginButton(false);
    setTimeout(() => {
      firebase
        .signInWithEmailAndPassword(firebase.auth, user.email, password)
        .then((userCredential) => {})
        .catch((error) => {
          console.log(error.message);
          console.log(error.code);
          if (error.code === "auth/wrong-password") {
            setShowLoginButton(true);
            setPasswordError(true);
            setPasswordLabelText("Incorrect Password");
          }
        });
    }, 10);
  };
  const modalBody = (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: theme.isDarkMode
            ? "rgba(0, 0, 0, 0.4)"
            : "rgba(0, 0, 0, 0.1)",
        }}
        onClick={handleClose}
      ></div>
      <div
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: 250,
            height: passwordless ? 250 : 350,
            borderRadius: "10px",
            backgroundColor: theme.isDarkMode
              ? theme.darkModeValues.darkModeBackground
              : theme.darkModeValues.lightModeBackground,
            padding: "20px",
            display: "flex",
            justifyContent: passwordless ? "center" : "flex-start",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={classes.avatarContainerStyleModal}>
            <Avatar
              variant="circular"
              alt={user.displayName}
              src={user.photoURL}
              sx={{
                width: 100,
                height: 100,
                marginTop: "8px",
                marginBottom: "15px",
                fontSize: "3rem",
              }}
            />
            <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
              <ScaleText>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    wordBreak: "keep-all",
                    height: "100%",
                    userSelect: "none",
                  }}
                >
                  {user.displayName}
                </div>
              </ScaleText>
            </div>
          </div>
          <TextField
            style={{ display: "none" }}
            label="Username"
            autoFocus
            inputProps={{ readOnly: true }}
            type="email"
            value={user.email}
          />
          {!passwordless && (
            <>
              <TextField
                style={{ marginTop: "20px", width: "200px" }}
                className={classes.textFieldMulti}
                label={passwordLabelText}
                // autoFocus
                type="password"
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                error={passwordError}
              />
              <div
                style={{ position: "absolute", top: "244px", right: "15px" }}
              >
                <Tooltip
                  enterTouchDelay={10}
                  leaveTouchDelay={3000}
                  enterDelay={200}
                  leaveDelay={300}
                  title={
                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px",
                      }}
                    >
                      <span style={{ fontSize: "10px", marginTop: "5px" }}>
                        Password must must be longer than 6 characters
                      </span>
                    </div>
                  }
                >
                  <HelpIcon
                    style={{
                      color: theme.isDarkMode
                        ? "rgba(255, 255, 255, 0.6)"
                        : "rgba(0, 0, 0, 0.2)",
                      width: "20px",
                    }}
                  />
                </Tooltip>
              </div>
              {showLoginButton && (
                <>
                  <Button
                    style={{ marginTop: "25px", width: "200px" }}
                    variant="outlined"
                    onClick={handleLogin}
                    sx={{
                      color: theme.isDarkMode ? "#e5e5e5 !important" : ``,
                      borderColor: theme.isDarkMode ? "#e5e5e5 !important" : ``,
                    }}
                  >
                    Login
                  </Button>
                  {showForgotPasswordButton && (
                    <Button
                      onClick={() => {
                        firebase
                          .sendPasswordResetEmail(firebase.auth, user.email)
                          .then(() => {
                            setShowForgotPasswordButton(false);
                            setShowPasswordResetSent(true);
                          })
                          .catch((error) => {
                            console.log(error.code);
                            console.log(error.message);
                          });
                      }}
                      style={{
                        marginTop: "10px",
                        fontSize: "8px",
                        textTransform: "none",
                      }}
                      size="small"
                    >
                      Forgot Password
                    </Button>
                  )}
                  {showPasswordResetSent && (
                    <p
                      style={{
                        color: theme.isDarkMode
                          ? "rgba(255, 255, 255, 0.6)"
                          : "rgba(0, 0, 0, 0.2)",
                        fontSize: "12px",
                      }}
                    >
                      Password Reset Sent
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className={classes.avatarContainerStyle} onClick={handleClick}>
        <Avatar
          variant="circular"
          alt={user.displayName}
          src={user.photoURL}
          sx={{
            width: 100,
            height: 100,
            marginTop: "8px",
            marginBottom: "15px",
            fontSize: "3rem",
          }}
        />
        <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
          <ScaleText>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                wordBreak: "keep-all",
                height: "100%",
                userSelect: "none",
              }}
            >
              {user.displayName}
            </div>
          </ScaleText>
        </div>
      </div>
      <Modal hideBackdrop open={open} onClose={handleClose} disableScrollLock>
        {modalBody}
      </Modal>
    </>
  );
};

export default UserCard;
