import React, { useContext, useState, useEffect } from "react";

const ThemeContext = React.createContext();

const darkModeValues = {
  darkModeBackground: "#121212",
  lightModeBackground: "#f4f5f7",

  darkModeTextColour: "#e5e5e5",
  lightModeTextColour: "#121212",

  darkModeIconColour: "#a6a6a6",
  lightModeIconColour: "#595959",

  darkModeDrawerBackground: "#161616",
  lightModeDrawerBackground: "#f0f1f2",

  darkModeDrawerActiveBackground: "#1f1f1f",
  lightModeDrawerActiveBackground: "#dedfe0",
};

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const updateMetaThemeColour = ({ light, dark }) => {
    light = light.replace("#", "");
    dark = dark.replace("#", "");
    const existingMetas = Array.from(
      document.querySelectorAll("meta[name=theme-color]")
    );
    existingMetas.forEach((meta) => {
      meta.remove();
      meta.remove();
    });
    let lightMeta = document.createElement("meta");
    lightMeta.name = "theme-color";
    lightMeta.content = `#${light}`;
    lightMeta.media = "(prefers-color-scheme: light)";
    document.querySelector("head").append(lightMeta);
    let darkMeta = document.createElement("meta");
    darkMeta.name = "theme-color";
    darkMeta.content = `#${dark}`;
    darkMeta.media = "(prefers-color-scheme: dark)";
    document.querySelector("head").append(darkMeta);
  };
  const detectColourTheme = () => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? true
      : false;
  };
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setIsDarkMode(e.matches ? "dark" : "light")
      );
    setIsDarkMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);
  return (
    <ThemeContext.Provider
      value={{
        updateMetaThemeColour,
        detectColourTheme,
        isDarkMode,
        darkModeValues,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
