import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import PlaceIcon from "@mui/icons-material/Place";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const EventDataTab = ({ firebase, eventData, eventTypes, isMobile }) => {
  const [eventTitle, setEventTitle] = useState(eventData?.event?.title || "");
  const handleEventTitleChange = (e) => {
    setEventTitle(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { title: e.target.value }
    );
  };
  const [eventLocation, setEventLocation] = useState(
    eventData?.event?.extendedProps?.location || ""
  );
  const handleEventLocationChange = (e) => {
    setEventLocation(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { location: e.target.value }
    );
  };
  const [eventType, setEventType] = useState(
    eventData?.event?.extendedProps?.type || ""
  );
  const handleEventTypeChange = (e) => {
    if (
      eventData?.event?.extendedProps?.type?.toLowerCase().includes("birthday")
    ) {
      setEventAllDay(false);
      firebase.updateDoc(
        firebase.doc(firebase.db, "events", eventData.event.id),
        { allDay: false, recurring: false }
      );
    }
    setEventType(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { type: e.target.value }
    );

    if (e.target.value.toLowerCase().includes("birthday")) {
      setEventAllDay(true);
      firebase.updateDoc(
        firebase.doc(firebase.db, "events", eventData.event.id),
        { allDay: true, recurring: true }
      );
    }
  };
  const [eventAllDay, setEventAllDay] = useState(
    eventData?.event?.allDay || false
  );
  const handleEventAllDayChange = (e) => {
    setEventAllDay(e.target.checked);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { allDay: e.target.checked }
    );
  };
  const [eventStart, setEventStart] = useState(eventData?.event?.start || null);
  const handleEventStartChange = (e) => {
    try {
      setEventStart(new Date(e).toISOString());
      firebase.updateDoc(
        firebase.doc(firebase.db, "events", eventData.event.id),
        { start: new Date(e).toISOString() }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const [birthday, setBirthday] = useState(
    eventData?.event?.extendedProps.startOrig || null
  );
  const handleBirthdayChange = (e) => {
    try {
      setBirthday(new Date(e).toISOString());
      firebase.updateDoc(
        firebase.doc(firebase.db, "events", eventData.event.id),
        { start: new Date(e).toISOString() }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const [eventEnd, setEventEnd] = useState(eventData?.event?.end || null);
  const handleEventEndChange = (e) => {
    setEventEnd(new Date(e).toISOString());
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { end: new Date(e).toISOString() }
    );
  };
  const [eventNotes, setEventNotes] = useState(
    eventData?.event?.extendedProps?.notes || ""
  );
  const handleEventNotesChange = (e) => {
    setEventNotes(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { notes: e.target.value }
    );
  };
  useEffect(() => {
    setEventTitle(eventData?.event?.title || "");
    setEventLocation(eventData?.event?.extendedProps?.location || "");
    setEventType(eventData?.event?.extendedProps?.type || "");
    setEventAllDay(eventData?.event?.allDay || false);
    setEventStart(eventData?.event?.start || false);
    setEventEnd(eventData?.event?.end || false);
    setEventNotes(eventData?.event?.extendedProps?.notes || "");
  }, [eventData]);
  const handleLocationClick = () => {
    let formatted = eventLocation.replace(" ", "+");
    window.open(`http://maps.apple.com/?q=${formatted}`);
  };
  const timeContainerStyle = {
    display: "flex",
    marginTop: 20,
    justifyContent: "space-between",
  };
  return (
    <>
      {!eventData?.event?.extendedProps?.type
        ?.toLowerCase()
        .includes("birthday") ? (
        <NormalEvents
          eventTypes={eventTypes}
          eventTitle={eventTitle}
          handleEventTitleChange={handleEventTitleChange}
          eventLocation={eventLocation}
          handleEventLocationChange={handleEventLocationChange}
          handleLocationClick={handleLocationClick}
          eventType={eventType}
          handleEventTypeChange={handleEventTypeChange}
          eventAllDay={eventAllDay}
          handleEventAllDayChange={handleEventAllDayChange}
          eventStart={eventStart}
          handleEventStartChange={handleEventStartChange}
          eventEnd={eventEnd}
          handleEventEndChange={handleEventEndChange}
          eventNotes={eventNotes}
          handleEventNotesChange={handleEventNotesChange}
          isMobile={isMobile}
          timeContainerStyle={timeContainerStyle}
        />
      ) : (
        <BirthdayEvents
          eventTypes={eventTypes}
          eventTitle={eventTitle}
          handleEventTitleChange={handleEventTitleChange}
          eventLocation={eventLocation}
          handleEventLocationChange={handleEventLocationChange}
          handleLocationClick={handleLocationClick}
          eventType={eventType}
          handleEventTypeChange={handleEventTypeChange}
          eventAllDay={eventAllDay}
          handleEventAllDayChange={handleEventAllDayChange}
          eventStart={birthday}
          handleEventStartChange={handleBirthdayChange}
          eventEnd={eventEnd}
          handleEventEndChange={handleEventEndChange}
          eventNotes={eventNotes}
          handleEventNotesChange={handleEventNotesChange}
          isMobile={isMobile}
          timeContainerStyle={timeContainerStyle}
        />
      )}
    </>
  );
};

export default EventDataTab;

const BirthdayEvents = ({
  eventTypes,
  eventTitle,
  handleEventTitleChange,
  eventType,
  handleEventTypeChange,
  eventStart,
  handleEventStartChange,
}) => {
  return (
    <>
      <TextField
        sx={{ marginBottom: 2, marginTop: 5 }}
        label="Whos Birthday?"
        value={eventTitle}
        onChange={handleEventTitleChange}
        variant="outlined"
      />
      <FormControl fullWidth>
        <InputLabel>Event Type</InputLabel>
        <Select
          label="Event Type"
          value={eventType}
          displayEmpty
          onChange={handleEventTypeChange}
          sx={{ marginBottom: 2 }}
        >
          {eventTypes &&
            eventTypes.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={"Birthday"}
          inputFormat="dd/MM/yyyy"
          value={eventStart || null}
          onChange={handleEventStartChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </>
  );
};

const NormalEvents = ({
  eventTypes,
  eventTitle,
  handleEventTitleChange,
  eventLocation,
  handleEventLocationChange,
  handleLocationClick,
  eventType,
  handleEventTypeChange,
  eventAllDay,
  handleEventAllDayChange,
  eventStart,
  handleEventStartChange,
  eventEnd,
  handleEventEndChange,
  eventNotes,
  handleEventNotesChange,
  isMobile,
  timeContainerStyle,
}) => {
  return (
    <>
      <TextField
        sx={{ marginBottom: 2, marginTop: 5 }}
        label="Event Title"
        value={eventTitle}
        onChange={handleEventTitleChange}
        variant="outlined"
      />
      <TextField
        sx={{ marginBottom: 2 }}
        label="Location"
        value={eventLocation}
        onChange={handleEventLocationChange}
        variant="outlined"
        InputProps={{
          endAdornment: eventLocation ? (
            <InputAdornment position="end">
              <IconButton onClick={handleLocationClick}>
                <PlaceIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <FormControl fullWidth>
        <InputLabel>Event Type</InputLabel>
        <Select
          label="Event Type"
          value={eventType}
          displayEmpty
          onChange={handleEventTypeChange}
          sx={{ marginBottom: 2 }}
        >
          {eventTypes &&
            eventTypes.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormGroup style={{ display: isMobile ? "block" : "none" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={eventAllDay}
              onChange={handleEventAllDayChange}
            />
          }
          label="All Day"
        />
      </FormGroup>
      <div style={timeContainerStyle}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start"
            value={eventStart}
            inputVariant="outlined"
            onChange={handleEventStartChange}
            renderInput={(params) => (
              <TextField sx={{ marginBottom: 2 }} {...params} />
            )}
            inputFormat="dd/MM/yy HH:mm aaa"
          />
          <FormGroup style={{ display: isMobile ? "none" : "block" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eventAllDay}
                  onChange={handleEventAllDayChange}
                />
              }
              label={<span style={{ fontSize: 12 }}>All Day</span>}
              sx={{ marginRight: 0 }}
            />
          </FormGroup>
          <DateTimePicker
            sx={{ display: eventAllDay ? "none" : "block" }}
            label="End"
            value={eventEnd}
            inputVariant="outlined"
            onChange={handleEventEndChange}
            renderInput={(params) => (
              <TextField sx={{ marginBottom: 2 }} {...params} />
            )}
            inputFormat="dd/MM/yy HH:mm aaa"
          />
        </LocalizationProvider>
      </div>
      <TextField
        label="Notes"
        multiline
        rows={6}
        value={eventNotes}
        onChange={handleEventNotesChange}
      />
    </>
  );
};
