import React, { useState, useEffect } from "react";
// import { DragDropContext } from "react-beautiful-dnd";
import List from './List'


const Board = ({ board }) => {
    const [lists, setLists] = useState([])
    useEffect(() => {
        setLists([...Object.keys(board.lists)])
    }, [board])
    return (
        <div>
            {lists && lists.map((list, index) => <List key={`${list}=${index}`} list={board.lists[list]} />)}
        </div>
    )
}
export default Board