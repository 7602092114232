import React from "react";

import Main from "./Main";
import { FirebaseProvider } from "./FirebaseContext";
import { ThemeProvider } from "./ThemeContext";
import { WebKitProvider } from "./WebKitContext";

// import { useWebKit } from "./WebKitContext";

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "hsl(0, 0%, 10%)",
    },
  },
});

// console.log(window.webkit);
function App() {
  // useEffect(() => {
  //   if (window.webkit) {
  //     document.getElementById("test").innerText = "SUCCESS";
  //   } else {
  //     document.getElementById("test").innerText = "NOPE";
  //   }
  // }, []);

  return (
    <MuiThemeProvider theme={darkTheme}>
      <WebKitProvider>
        <FirebaseProvider>
          <ThemeProvider>
            <>
              {/* <Test /> */}
              <Main />
            </>
          </ThemeProvider>
        </FirebaseProvider>
      </WebKitProvider>
    </MuiThemeProvider>
  );
}

export default App;

// const Test = () => {
//   const WebKit = useWebKit();
//   // console.log(WebKit.webKitAvailable);
//   return WebKit.webKitAvailable ? (
//     <>
//       <button
//         onClick={() => {
//           window.webkit.messageHandlers.bridge.postMessage("trigger from JS");
//         }}
//       >
//         test
//       </button>
//       <span id="test"></span>
//     </>
//   ) : null;
// };

/* <Route path='/' exact element={user
  ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
    <Kanban darkModeValues={darkModeValues} isDarkMode={isDarkMode} app={app} />
  </Layout>
  :
  <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
/> */

// function App() {
//   const [isDarkMode, setIsDarkMode] = useState(DetectColourTheme())
//   const [user] = useAuthState(auth)

//   useEffect(() => {
//     window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setIsDarkMode(e.matches ? true : false));
//     return () => {
//       window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
//       });
//     }
//   }, []);
//   const handleRefresh = () => window.location.reload()

//   return (
//     <Div100vh>
//       <PullToRefresh
//         onRefresh={handleRefresh}
//         resistance={4}
//         backgroundColor={isDarkMode ? darkModeValues.darkModeBackground : darkModeValues.lightModeBackground}
//         pullingContent={null}
//       >
//         <Router>
//           <Routes>
//             <Route path='/' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <Kanban darkModeValues={darkModeValues} isDarkMode={isDarkMode} app={app} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//             <Route path='/calendar' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <Calendar darkModeValues={darkModeValues} isDarkMode={isDarkMode} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//             <Route path='/list' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <List darkModeValues={darkModeValues} isDarkMode={isDarkMode} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//             <Route path='/clients' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <Clients darkModeValues={darkModeValues} isDarkMode={isDarkMode} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//             <Route path='/staff' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <Staff darkModeValues={darkModeValues} isDarkMode={isDarkMode} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//             <Route path='/admin' exact element={user
//               ? <Layout signOut={signOut} auth={auth} darkModeValues={darkModeValues} isDarkMode={isDarkMode}>
//                 <Admin darkModeValues={darkModeValues} isDarkMode={isDarkMode} />
//               </Layout>
//               :
//               <Login returnUserEmails={returnUserEmails} auth={auth} signIn={signInWithEmailAndPassword} resetPassword={sendPasswordResetEmail} darkModeValues={darkModeValues} />}
//             />
//           </Routes>
//         </Router>
//       </PullToRefresh>
//     </Div100vh>
//   );
// }

// const [jobData, setJobData] = useState([])
// console.log(jobData);
// useEffect(() => {
//     const q = query(collection(db, "jobData"));
//     const unsubscribe = onSnapshot(q, (querySnapshot) => {
//       const data = [];
//       querySnapshot.forEach((doc) => {
//         data.push(doc.data());
//       });
//       setJobData([...data])
//     });
//   return () => {
//     unsubscribe()
//   }
// }, [user])
