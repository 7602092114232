import React from 'react'

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from '../../ThemeContext';


const CreateListItem = ({ textColours, item, navigate, location }) => {
    //Declares custom theme variable
    const theme = useTheme()

    return (
        <ListItem
            key={item.text}
            button
            onClick={() => { navigate(item.path) }}
            style={{
                background: parseBackgroundColour(location, item, theme)
            }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} sx={textColours}></ListItemText>
        </ListItem>
    )
}

export default CreateListItem

const parseBackgroundColour = (location, item, theme) => {
    let isActive = false
    if (location.pathname === '/' && item.path === '/calendar') { isActive = true }
    if (location.pathname === item.path) { isActive = true }
    if (isActive) {
        if (theme.isDarkMode) {
            return theme.darkModeValues.darkModeDrawerActiveBackground
        } else {
            return theme.darkModeValues.lightModeDrawerActiveBackground
        }
    }
    return null
}