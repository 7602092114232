import React, { useContext, useState, useEffect } from "react";

const WebKitContext = React.createContext();

export function useWebKit() {
  return useContext(WebKitContext);
}

export function WebKitProvider({ children }) {
  const [webKitAvailable, setWebKitAvailable] = useState(false);
  const detectWebKit = () => {
    return window.webkit ? true : false;
  };
  useEffect(() => {
    setWebKitAvailable(detectWebKit);
  }, []);
  return (
    <WebKitContext.Provider
      value={{
        webKitAvailable,
      }}
    >
      {children}
    </WebKitContext.Provider>
  );
}
