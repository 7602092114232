import React, { useState } from "react";

//Allows for routing to different "Pages"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Fixes the viewport issues that iOS / iPadOS cause in Safari
import Div100vh from "react-div-100vh";
//Allows pull to refresh on mobile devices when used in a PWA, This works by default in Safari but not present in installed PWA
// import PullToRefresh from 'react-simple-pull-to-refresh';

//Components
// Listed in the order of user flow
import Login from "./components/Login";

import Layout from "./components/Layout";

import Kanban from "./components/Kanban";
import Calendar from "./components/Calendar";
import List from "./components/List";
import Invoice from "./components/Invoice";
import ActionButtonEvent from "./components/ActionButtonEvent";

// import Clients from './components/Clients'
// import Staff from './components/Staff'
// import Admin from './components/Admin'

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from "./FirebaseContext";
//Imports custom theme hook, exposing custom theme functions and states
// import { useTheme } from './ThemeContext';

const Main = () => {
  //Declares custom firebase variable
  const firebase = useFirebase();
  //Declares custom theme variable
  // const theme = useTheme()

  //Pull to refresh function, just reloads the page
  // const handleRefresh = () => window.location.reload()

  const [layoutMenuDisabled, setLayoutMenuDisabled] = useState(false);

  return (
    <Div100vh>
      {/* <PullToRefresh
                onRefresh={handleRefresh}
                resistance={4}
                backgroundColor={theme.isDarkMode ? theme.darkModeValues.darkModeBackground : theme.darkModeValues.lightModeBackground}
                pullingContent={null}> */}
      <Router>
        <Routes>
          {/* <Route path='/' exact element={<Calendar />} /> */}
          <Route
            path="/"
            exact
            element={
              firebase.user ? (
                <Layout layoutMenuDisabled={layoutMenuDisabled}>
                  <Calendar setLayoutMenuDisabled={setLayoutMenuDisabled} />
                </Layout>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/invoice"
            exact
            element={
              <Invoice
                dateOfIssue="03/02/23"
                dueDate="13/02/23"
                billedName="Alex M-S"
                billedEmail="alexmorisseysmith@gmail.com"
                companyName="Morrissey Media"
                invoiceNumber={23002}
                invoiceIsPaid={false}
                clientPaysSuperannuation={true}
                showPersonalDetails={false}
                billingItems={[
                  { description: "Test", qtyHrs: "", unitPrice: "" },
                ]}
              />
            }
          />
          {/* <Route path='/' exact element={firebase.user ? <Layout layoutMenuDisabled={layoutMenuDisabled}><Kanban /></Layout> : <Login />} /> */}
          <Route
            path="/kanban"
            exact
            element={
              firebase.user ? (
                <Layout layoutMenuDisabled={layoutMenuDisabled}>
                  <Kanban />
                </Layout>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/list"
            exact
            element={
              firebase.user ? (
                <Layout layoutMenuDisabled={layoutMenuDisabled}>
                  <List />
                </Layout>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/calendar"
            exact
            element={
              firebase.user ? (
                <Layout layoutMenuDisabled={layoutMenuDisabled}>
                  <Calendar setLayoutMenuDisabled={setLayoutMenuDisabled} />
                </Layout>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/actionbuttonevent"
            exact
            element={
              firebase.user ? (
                <ActionButtonEvent firebase={firebase} />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </Router>
      {/* </PullToRefresh> */}
    </Div100vh>
  );
};

export default Main;
