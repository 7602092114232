import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { makeStyles } from "@mui/styles";

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from "../../FirebaseContext";
//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from "../../ThemeContext";

import { useWebKit } from "../../WebKitContext";

// const { Fido2Lib } = require("fido2-lib");

// const f2l = new Fido2Lib({
//   timeout: 600,
//   rpName: "https://videsk.morrisseymedia.com.au",
//   rpIcon:
//     "https://firebasestorage.googleapis.com/v0/b/videsk-system.appspot.com/o/logo%2Flogo.png?alt=media&token=2057e90c-10c1-4d80-b5e5-30fb063be9c3",
//   challengeSize: 128,
//   attestation: "direct",
//   cryptoParams: [-7],
//   authenticatorAttachment: "platform",
//   authenticatorRequireResidentKey: false,
//   authenticatorUserVerification: "required",
// });
const UserMenu = () => {
  //Declares custom theme variable
  const theme = useTheme();
  //Declares custom firebase variable
  const firebase = useFirebase();
  const WebKit = useWebKit();
  const handleSignOut = () => firebase.signOut(firebase.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (WebKit.webKitAvailable) {
      window.generateAccessToken = async (arg) => {
        // const res = await firebase.generateiOSAccessToken();
        const fakeAccessToken = "abcdefghij";
        console.log(fakeAccessToken);
        window.webkit.messageHandlers.bridge.postMessage(
          `registerAccessToken-${fakeAccessToken}`
        );
      };
    }
  }, [WebKit.webKitAvailable]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = makeStyles({
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: theme.isDarkMode
          ? theme.darkModeValues.darkModeBackground
          : theme.darkModeValues.lightModeBackground,
        color: theme.isDarkMode
          ? theme.darkModeValues.darkModeTextColour
          : theme.darkModeValues.lightModeTextColour,
      },
    },
  })();

  //   const registerPasswordlessLogin = async () => {
  //     if (WebKit.webKitAvailable) {
  //       window.webkit.messageHandlers.bridge.postMessage("generateAccessToken");
  //       return;
  //     }
  //     try {
  //       if (
  //         !(await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable())
  //       ) {
  //         alert(`Not supported on your device 😢
  // Check with the system administrator if you think this should be working...`);
  //         return;
  //       }
  //     } catch (e) {
  //       alert(`Not supported on your device 😢
  //     Check with the system administrator if you think this should be working...`);
  //       return;
  //     }
  //     const registrationObject = {};
  //     const registrationOptions = await f2l.attestationOptions();
  //     registrationOptions.user.id = Buffer.from(new Uint8Array(firebase.user.id));
  //     registrationOptions.user.name = firebase.user.email;
  //     registrationOptions.user.displayName = firebase.user.displayName;
  //     registrationOptions.challenge = Buffer.from(registrationOptions.challenge);

  //     const attestationOptions = { publicKey: registrationOptions };

  //     handleMenuClose();

  //     const clientAttestationResponse = await navigator.credentials
  //       .create(attestationOptions)
  //       .catch((e) => console.log(e));

  //     if (!clientAttestationResponse) return;

  //     registrationObject.id = clientAttestationResponse.id;
  //     const attestationExpectations = {
  //       challenge: attestationOptions.publicKey.challenge,
  //       origin: removeTrailingSlash(window.location.href),
  //       factor: "either",
  //     };
  //     const regResult = await f2l.attestationResult(
  //       clientAttestationResponse,
  //       attestationExpectations
  //     );
  //     const authnrData = Object.fromEntries(regResult.authnrData);
  //     registrationObject.credentialPublicKeyPem =
  //       authnrData.credentialPublicKeyPem;
  //     registrationObject.counter = authnrData.counter;

  //     const res = await firebase.saveAuthCredentials(registrationObject);
  //     console.log(res);

  //     function removeTrailingSlash(str) {
  //       return str.replace(/\/+$/, "");
  //     }
  //   };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={handleMenuClick}>
        <Avatar alt={firebase.user.displayName} src={firebase.user.photoURL} />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        disableScrollLock={true}
        className={classes.menu}

        // sx={{ backgroundColor: 'red' }}
        // style={{ backgroundColor: 'red' }}
      >
        <MenuItem disabled>
          <p style={{ paddingRight: "20px" }}>{firebase.user.displayName}</p>
        </MenuItem>
        <MenuItem disabled>
          <p style={{ paddingRight: "20px" }}>{firebase.user.email}</p>
        </MenuItem>
        {/* <MenuItem onClick={registerPasswordlessLogin}>
          <p style={{ paddingRight: "20px" }}>Register Passwordless Login</p>
        </MenuItem> */}
        <MenuItem onClick={handleSignOut}>
          <ExitToAppIcon style={{ paddingRight: "10px" }} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;

// //Get Challenge
// const request = await fetch("http://localhost:8000/getWebAuthnChallenge", {
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       name: firebase.user.email,
//       displayName: firebase.user.displayName,
//       id: firebase.user.i,
//     }),
//   }).then((res) => res.json());

//   console.log(request);

//   //Get Auth Key
//   const response = await create(request);

//   // handleMenuClose();

//   console.log(response);

//   //Send Result To Server
//   await fetch("http://localhost:8000/verifyAndSaveAuthentication", {
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       id: response.id,
//       clientAttestationResponse:response,
//       attestationExpectations: {
//         challenge: request.publicKey.challenge,
//         //   origin: window.location.href.replace(/\/[^\/]*$/, ""),
//         factor: "either",
//       },
//     }),
//   });

//   return;
