import React, { useContext } from "react";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  where,
  collection,
  doc,
  onSnapshot,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {
  const firebaseConfig = {
    apiKey: "AIzaSyAOQdHnopH82RFLwUkKmGICPIRKRyCJRZw",
    authDomain: "videsk-system.firebaseapp.com",
    projectId: "videsk-system",
    storageBucket: "videsk-system.appspot.com",
    messagingSenderId: "177704689515",
    appId: "1:177704689515:web:9727b2bee5229fdc02362e",
    measurementId: "G-Y1691RS469",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth();
  const functions = getFunctions(app);

  const [user] = useAuthState(auth);

  const returnUserEmails = httpsCallable(functions, "returnUserEmails");
  const getAuthToken = httpsCallable(functions, "getAuthToken");
  const saveAuthCredentials = httpsCallable(functions, "saveAuthCredentials");
  const generateiOSAccessToken = httpsCallable(
    functions,
    "generateiOSAccessToken"
  );
  const sendEmail = httpsCallable(functions, "sendEmail");
  const getLatestInvoiceNumber = httpsCallable(
    functions,
    "getLatestInvoiceNumber"
  );

  return (
    <FirebaseContext.Provider
      value={{
        app,
        db,
        query,
        where,
        collection,
        doc,
        onSnapshot,
        addDoc,
        updateDoc,
        getDoc,
        deleteDoc,
        orderBy,
        auth,
        user,
        signInWithEmailAndPassword,
        sendPasswordResetEmail,
        signInWithCustomToken,
        signOut,
        functions,
        returnUserEmails,
        getAuthToken,
        saveAuthCredentials,
        generateiOSAccessToken,
        sendEmail,
        getLatestInvoiceNumber,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}

// import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
// import { getFirestore, collection, onSnapshot, query, where, getDocs, } from "firebase/firestore";
