import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import PlaceIcon from "@mui/icons-material/Place";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useFirebase } from "../FirebaseContext";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";

import { use100vh } from "react-div-100vh";

const eventTypes = {
  Photography: {
    backgroundColour: "hsla(220, 25%, 20%, 0.7)",
    textColour: "hsl(220, 70%, 70%)",
  },
  "Photo Assistant": {
    backgroundColour: "hsla(200, 25%, 20%, 0.7)",
    textColour: "hsl(200, 70%, 70%)",
  },
  Videography: {
    backgroundColour: "hsla(280, 25%, 20%, 0.7)",
    textColour: "hsl(280, 70%, 70%)",
  },
  "Video Assistant": {
    backgroundColour: "hsla(260, 25%, 20%, 0.7)",
    textColour: "hsl(260, 70%, 70%)",
  },
  Retouching: {
    backgroundColour: "hsla(180, 25%, 20%, 0.7)",
    textColour: "hsl(180, 70%, 70%)",
  },
  "Video Editing": {
    backgroundColour: "hsla(160, 25%, 20%, 0.7)",
    textColour: "hsl(160, 70%, 70%)",
  },
  Coding: {
    backgroundColour: "hsla(140, 25%, 20%, 0.7)",
    textColour: "hsl(140, 70%, 70%)",
  },
  Misc: {
    backgroundColour: "hsla(120, 25%, 20%, 0.7)",
    textColour: "hsl(120, 70%, 70%)",
  },
  "Birthday 🥳": {
    backgroundColour: "hsla(340, 25%, 20%, 0.7)",
    textColour: "hsl(340, 70%, 70%)",
  },
};

const ActionButtonEvent = () => {
  const firebase = useFirebase();
  const height = use100vh();
  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("sm"));

  const containerStyle = {
    background: "rgb(26, 26, 26)",
    margin: 0,
    width: "100vw",
    height: height,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  const [submitted, setSubmitted] = useState(false);

  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventType, setEventType] = useState("");
  const [eventAllDay, setEventAllDay] = useState(false);
  const [eventStart, setEventStart] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const [eventNotes, setEventNotes] = useState("");

  const innerStyle = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "95vw",
    minWidth: 400,
  };
  const newEventStyle = {
    fontSize: 26,
    fontWeight: "bold",
    marginTop: 20,
    color: "hsl(0, 0%, 80%)",
    // marginBottom: 6,
  };
  return (
    <div style={containerStyle}>
      <div style={innerStyle}>
        {submitted ? (
          <>
            <Button
              variant="contained"
              sx={{ margin: 4, minWidth: "100%", height: 50 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Make New Event
            </Button>
          </>
        ) : (
          <>
            <span style={newEventStyle}>
              NEW
              {eventType?.toLowerCase().includes("birthday")
                ? " BIRTHDAY"
                : " EVENT"}
            </span>
            {eventType?.toLowerCase().includes("birthday") ? (
              <BirthdayEvents
                eventTitle={eventTitle}
                setEventTitle={setEventTitle}
                eventType={eventType}
                setEventType={setEventType}
                eventStart={eventStart}
                setEventStart={setEventStart}
                setEventAllDay={setEventAllDay}
              />
            ) : (
              <NormalEvents
                eventTitle={eventTitle}
                setEventTitle={setEventTitle}
                eventLocation={eventLocation}
                setEventLocation={setEventLocation}
                eventType={eventType}
                setEventType={setEventType}
                eventAllDay={eventAllDay}
                setEventAllDay={setEventAllDay}
                eventStart={eventStart}
                setEventStart={setEventStart}
                eventEnd={eventEnd}
                setEventEnd={setEventEnd}
                eventNotes={eventNotes}
                setEventNotes={setEventNotes}
                isMobile={isMobile}
              />
            )}
            <Button
              variant="contained"
              sx={{ margin: 4, minWidth: "100%", height: 50 }}
              onClick={async () => {
                const event = {
                  title: eventTitle,
                  location: eventLocation,
                  type: eventType,
                  allDay: eventAllDay,
                  start: eventStart,
                  end: eventEnd,
                  notes: eventNotes,
                };
                await firebase.addDoc(
                  firebase.collection(firebase.db, "events"),
                  event
                );
                setEventTitle("");
                setEventLocation("");
                setEventType("");
                setEventAllDay(false);
                setEventStart(null);
                setEventEnd(null);
                setEventNotes("");
                setSubmitted(true);
              }}
            >
              Make Event
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ActionButtonEvent;
const BirthdayEvents = ({
  eventTitle,
  setEventTitle,
  eventType,
  setEventType,
  eventStart,
  setEventStart,
  setEventAllDay,
}) => {
  return (
    <>
      <TextField
        fullWidth
        sx={{ marginBottom: 2, marginTop: 5 }}
        label="Whos Birthday?"
        value={eventTitle}
        onChange={(e) => {
          setEventTitle(e.target.value);
        }}
        variant="outlined"
      />
      <FormControl fullWidth>
        <InputLabel>Event Type</InputLabel>
        <Select
          label="Event Type"
          value={eventType}
          displayEmpty
          onChange={(e) => {
            if (eventType?.toLowerCase().includes("birthday")) {
              setEventAllDay(false);
            }
            setEventType(e.target.value);
            if (e.target.value.toLowerCase().includes("birthday")) {
              setEventAllDay(true);
            }
          }}
          sx={{ marginBottom: 2 }}
        >
          {Object.keys(eventTypes) &&
            Object.keys(eventTypes).map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={"Birthday"}
          inputFormat="dd/MM/yyyy"
          value={eventStart || null}
          onChange={(e) => {
            try {
              if (e) setEventStart(new Date(e).toISOString());
            } catch (e) {}
          }}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </LocalizationProvider>
    </>
  );
};
const NormalEvents = ({
  eventTitle,
  setEventTitle,
  eventLocation,
  setEventLocation,
  eventType,
  setEventType,
  eventAllDay,
  setEventAllDay,
  eventStart,
  setEventStart,
  eventEnd,
  setEventEnd,
  eventNotes,
  setEventNotes,
  isMobile,
}) => {
  const handleLocationClick = () => {
    let formatted = eventLocation.replace(" ", "+");
    window.open(`http://maps.apple.com/?q=${formatted}`);
  };
  const timeContainerStyle = {
    display: "flex",
    marginTop: 20,
    justifyContent: "space-between",
  };
  return (
    <>
      <TextField
        fullWidth
        sx={{ marginBottom: 2, marginTop: 5 }}
        label="Event Title"
        value={eventTitle}
        onChange={(e) => {
          setEventTitle(e.target.value);
        }}
        variant="outlined"
      />
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        label="Location"
        value={eventLocation}
        onChange={(e) => {
          setEventLocation(e.target.value);
        }}
        variant="outlined"
        InputProps={{
          endAdornment: eventLocation ? (
            <InputAdornment position="end">
              <IconButton onClick={handleLocationClick}>
                <PlaceIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <FormControl fullWidth>
        <InputLabel>Event Type</InputLabel>
        <Select
          label="Event Type"
          value={eventType}
          displayEmpty
          onChange={(e) => {
            setEventType(e.target.value);
          }}
          sx={{ marginBottom: 2 }}
        >
          {Object.keys(eventTypes) &&
            Object.keys(eventTypes).map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormGroup style={{ display: isMobile ? "block" : "none" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={eventAllDay}
              onChange={(e) => {
                setEventAllDay(e.target.checked);
              }}
            />
          }
          label={<span style={{ color: "white" }}>All Day</span>}
        />
      </FormGroup>
      <div style={timeContainerStyle}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start"
            value={eventStart}
            inputVariant="outlined"
            onChange={(e) => {
              try {
                if (e) setEventStart(new Date(e).toISOString());
              } catch (e) {}
            }}
            renderInput={(params) => (
              <TextField sx={{ marginBottom: 2, marginRight: 2 }} {...params} />
            )}
            inputFormat="dd/MM/yy HH:mm aaa"
          />
          <FormGroup style={{ display: isMobile ? "none" : "block" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eventAllDay}
                  onChange={(e) => {
                    setEventAllDay(e.target.checked);
                  }}
                />
              }
              label={<span style={{ color: "white" }}>All Day</span>}
              sx={{ marginRight: 0 }}
            />
          </FormGroup>
          <DateTimePicker
            sx={{ display: eventAllDay ? "none" : "block" }}
            label="End"
            value={eventEnd}
            inputVariant="outlined"
            onChange={(e) => {
              try {
                if (e) setEventEnd(new Date(e).toISOString());
              } catch (e) {}
            }}
            renderInput={(params) => (
              <TextField sx={{ marginBottom: 2, marginLeft: 2 }} {...params} />
            )}
            inputFormat="dd/MM/yy HH:mm aaa"
          />
        </LocalizationProvider>
      </div>
      <TextField
        fullWidth
        label="Notes"
        multiline
        rows={6}
        value={eventNotes}
        onChange={(e) => {
          setEventNotes(e.target.value);
        }}
      />
    </>
  );
};
