import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";

import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ContactsIcon from "@mui/icons-material/Contacts";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PeopleIcon from "@mui/icons-material/People";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ListIcon from "@mui/icons-material/List";

// import { use100vh } from 'react-div-100vh'

import Logo from "../images/logo.png";

import CreateListItem from "./layout/CreateListItem";
import UserMenu from "./layout/UserMenu";

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from "../FirebaseContext";
//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from "../ThemeContext";

// import { WebAuthnApp } from "webauthn-simple-app";
// import { encode, decode } from "base64-arraybuffer";

const drawerWidth = 190;
const Layout = ({ layoutMenuDisabled, children }) => {
  //Declares custom firebase variable
  const firebase = useFirebase();
  //Declares custom theme variable
  const theme = useTheme();
  const MUITheme = useMUITheme();
  const mobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  const openedMixin = (themeMui) => ({
    width: drawerWidth,
    transition: themeMui.transitions.create("width", {
      easing: themeMui.transitions.easing.sharp,
      duration: themeMui.transitions.duration.enteringScreen,
    }),
    background: theme.isDarkMode
      ? theme.darkModeValues.darkModeDrawerBackground
      : theme.darkModeValues.lightModeDrawerBackground,
    overflowX: "hidden",
    // paddingLeft: 8,
    // '@media only screen and (max-width: 600px)': {
    //     paddingLeft: 0,
    // }
  });
  const closedMixin = (themeMui) => ({
    transition: themeMui.transitions.create("width", {
      easing: themeMui.transitions.easing.sharp,
      duration: themeMui.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${themeMui.spacing(7)})`,
    background: theme.isDarkMode
      ? theme.darkModeValues.darkModeDrawerBackground
      : theme.darkModeValues.lightModeDrawerBackground,
    [themeMui.breakpoints.up("sm")]: {
      width: `calc(${themeMui.spacing(7)} + 1px)`,
    },
    [themeMui.breakpoints.down("sm")]: {
      width: `calc(${themeMui.spacing(0)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    backgroundColor: "red",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  theme.updateMetaThemeColour({
    light: theme.darkModeValues.lightModeBackground,
    dark: theme.darkModeValues.darkModeBackground,
  });
  const handleSignOut = () => firebase.signOut(firebase.auth);
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line
  const [isAdmin, setIsAdmin] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const menuIconColours = {
    color: theme.isDarkMode
      ? theme.darkModeValues.darkModeIconColour
      : theme.darkModeValues.lightModeIconColour,
  };
  const dividerColours = {
    background: theme.isDarkMode
      ? theme.darkModeValues.darkModeTextColour
      : theme.darkModeValues.lightModeTextColour,
  };
  const textColours = {
    color: theme.isDarkMode
      ? theme.darkModeValues.darkModeTextColour
      : theme.darkModeValues.lightModeTextColour,
  };

  const jobMenuItems = [
    {
      text: "Calendar",
      icon: <CalendarTodayIcon sx={menuIconColours} />,
      path: "/calendar",
    },
    {
      text: "Kanban",
      icon: <ViewWeekIcon sx={menuIconColours} />,
      path: "/kanban",
    },
    {
      text: "List",
      icon: <ListIcon sx={menuIconColours} />,
      path: "/list",
    },
  ];
  const additionalMenuItems = [
    {
      text: "Clients",
      icon: <ContactsIcon sx={menuIconColours} />,
      path: "/clients",
    },
    {
      text: "Staff",
      icon: <PeopleIcon sx={menuIconColours} />,
      path: "/staff",
    },
    {
      text: "Admin Tools",
      icon: <SupervisedUserCircleIcon sx={menuIconColours} />,
      path: "/admin",
      admin: true,
    },
  ];
  return (
    <div
      style={{
        color: theme.isDarkMode
          ? theme.darkModeValues.lightModeTextColour
          : theme.darkModeValues.darkModeTextColour,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "hsla(260, 50%, 10%, 0.5)",
            // backgroundColor: theme.isDarkMode
            //   ? theme.darkModeValues.darkModeBackground
            //   : theme.darkModeValues.lightModeBackground,
          }}
        >
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start"
              disabled={layoutMenuDisabled}
              sx={{
                display: "none",
                marginLeft: "8px",
                marginRight: "25px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={menuIconColours} />
            </IconButton>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginRight: 15,
              }}
            >
              <img
                style={{ width: 40, marginLeft: 15 }}
                src={Logo}
                alt="ViDesk"
                onClick={handleSignOut}
              />
              {/* <Button onClick={register}>register</Button>
              <Button onClick={logIn}>log in</Button> */}

              <UserMenu />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={false}
          // variant="permanent"
          // open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon sx={menuIconColours} />
            </IconButton>
          </DrawerHeader>
          {/* <Divider sx={dividerColours} /> */}
          <List>
            {jobMenuItems.map((item) =>
              item.admin ? (
                isAdmin ? (
                  <CreateListItem
                    key={item.text}
                    item={item}
                    textColours={textColours}
                    navigate={navigate}
                    location={location}
                  />
                ) : null
              ) : (
                <CreateListItem
                  key={item.text}
                  item={item}
                  textColours={textColours}
                  navigate={navigate}
                  location={location}
                />
              )
            )}
            <Divider
              sx={{ marginTop: 1.2, marginBottom: 1.2, ...dividerColours }}
            />
            {additionalMenuItems.map((item) =>
              item.admin ? (
                isAdmin ? (
                  <CreateListItem
                    key={item.text}
                    item={item}
                    textColours={textColours}
                    navigate={navigate}
                    location={location}
                  />
                ) : null
              ) : (
                <CreateListItem
                  key={item.text}
                  item={item}
                  textColours={textColours}
                  navigate={navigate}
                  location={location}
                />
              )
            )}
          </List>
        </Drawer>
        <div
          style={{
            marginTop: 64,
            position: "relative",
            width: "100vw",
            height: `calc(100vh - ${mobile ? "150px" : "65px"})`,
          }}
        >
          {children}
        </div>
      </Box>
    </div>
  );
};

export default Layout;
