import React, { useState, useEffect, useRef } from "react";

import { useFirebase } from "../FirebaseContext";
import { useTheme } from "../ThemeContext";

// import { Button } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import enAu from "@fullcalendar/core/locales/en-au";
// eslint-disable-next-line
import rrulePlugin from "@fullcalendar/rrule";
// eslint-disable-next-line
import { RRule } from "rrule";

import "./calendar/calendar.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { use100vh } from "react-div-100vh";

import { useSwipeable } from "react-swipeable";

import "./style.css";

// import ReactDOMServer from "react-dom/server";
// import { jsPDF } from "jspdf";
// import Invoice from "./Invoice";

import "../fonts/Roboto-Regular-normal";
import EventDrawer from "./calendar/EventDrawer";
import DeletedEvents from "./calendar/DeletedEvents";
import debounce from "lodash.debounce";

const { DateTime } = require("luxon");

const eventTypes = {
  Photography: {
    backgroundColour: "hsla(220, 25%, 20%, 0.7)",
    textColour: "hsl(220, 70%, 70%)",
  },
  "Photo Assistant": {
    backgroundColour: "hsla(200, 25%, 20%, 0.7)",
    textColour: "hsl(200, 70%, 70%)",
  },
  Videography: {
    backgroundColour: "hsla(280, 25%, 20%, 0.7)",
    textColour: "hsl(280, 70%, 70%)",
  },
  "Video Assistant": {
    backgroundColour: "hsla(260, 25%, 20%, 0.7)",
    textColour: "hsl(260, 70%, 70%)",
  },
  Retouching: {
    backgroundColour: "hsla(180, 25%, 20%, 0.7)",
    textColour: "hsl(180, 70%, 70%)",
  },
  "Video Editing": {
    backgroundColour: "hsla(160, 25%, 20%, 0.7)",
    textColour: "hsl(160, 70%, 70%)",
  },
  Coding: {
    backgroundColour: "hsla(140, 25%, 20%, 0.7)",
    textColour: "hsl(140, 70%, 70%)",
  },
  Misc: {
    backgroundColour: "hsla(120, 25%, 20%, 0.7)",
    textColour: "hsl(120, 70%, 70%)",
  },
  "Birthday 🥳": {
    backgroundColour: "hsla(340, 25%, 20%, 0.7)",
    textColour: "hsl(340, 70%, 70%)",
  },
};

const Calendar = ({ setLayoutMenuDisabled }) => {
  const firebase = useFirebase();
  const theme = useTheme();

  const [events, setEvents] = useState([]);
  const [normalEvents, setNormalEvents] = useState([]);
  const [recurringEvents, setRecurringEvents] = useState([]);
  const [deletedEvents, setDeletedEvents] = useState([]);
  const [eventData, setEventData] = useState({});
  const calendarRef = useRef(null);

  const handleSwipeStart = (swipe) => {
    if (swipe.dir === "Left" || swipe.dir === "Right") {
      const eles = Array.from(document.getElementsByClassName("fc-scroller"));
      eles.forEach((ele) => (ele.style.overflow = "hidden"));
      document.body.style.overflow = "hidden";
    }
  };

  const handleSwipe = (swipe) => {
    if (swipe.dir === "Left" || swipe.dir === "Right") {
      const eles = Array.from(document.getElementsByClassName("fc-scroller"));
      eles.forEach((ele) => (ele.style.overflow = "hidden scroll"));
      document.body.style.overflow = "";
    }
  };
  const handleSwipeDebounce = debounce(handleSwipe, 100);

  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => calendarRef.current.getApi().prev(),
    onSwipedLeft: (eventData) => calendarRef.current.getApi().next(),
    onSwipeStart: handleSwipeStart,
    onSwiping: handleSwipeDebounce,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [datesInViewStart, setDatesInViewStart] = useState(null);
  const [datesInViewEnd, setDatesInViewEnd] = useState(null);

  useEffect(() => {
    setEvents([...normalEvents, ...recurringEvents]);
  }, [normalEvents, recurringEvents]);

  useEffect(() => {
    const databaseQuery = firebase.query(
      firebase.collection(firebase.db, "events"),
      firebase.where("start", ">=", startDate),
      firebase.where("start", "<=", endDate)
    );

    const unsub = firebase.onSnapshot(databaseQuery, (querySnapshot) => {
      const documents = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.color =
          eventTypes[data.type]?.backgroundColour || "hsla(350, 25%, 20%, 0.7)";
        data.textColor =
          eventTypes[data.type]?.textColour || "hsl(350, 70%, 70%)";

        if (
          data.id !== "-PLACEHOLDER" &&
          !data.type?.toLowerCase().includes("birthday")
        ) {
          documents.push(data);
        }
      });
      setNormalEvents([...documents]);
      scrollToTopOfEventsInView(
        [...documents],
        datesInViewStart,
        datesInViewEnd
      );
    });
    return () => {
      unsub();
    };
  }, [firebase, startDate, endDate, datesInViewStart, datesInViewEnd]);

  useEffect(() => {
    const databaseQuery = firebase.query(
      firebase.collection(firebase.db, "events"),
      firebase.where("recurring", "==", true)
    );

    const unsub = firebase.onSnapshot(databaseQuery, (querySnapshot) => {
      const recurringTmp = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.startOrig = data.start;
        data.color =
          eventTypes[data.type]?.backgroundColour || "hsla(350, 25%, 20%, 0.7)";
        data.textColor =
          eventTypes[data.type]?.textColour || "hsl(350, 70%, 70%)";

        data.rrule = {
          freq: RRule.YEARLY,
          interval: 1,
          dtstart: data.startOrig,
        };

        recurringTmp.push(data);
      });
      setRecurringEvents([...recurringTmp]);
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  useEffect(() => {
    let updatedEvent = { event: {} };
    if (eventData) {
      events.forEach((e) => {
        if (e.id === eventData?.event?.id) {
          const noop = () => {};
          const resEvent = calendarRef.current.getApi().getEventById(e.id);

          const eventKeys = Object.keys(e);

          eventKeys.forEach((key) => {
            if (key === "start") resEvent.setStart(e["start"]);
            else if (key === "end") resEvent.setEnd(e["end"]);
            else if (key === "allDay") resEvent.setAllDay(e["allDay"]);
            else if (key === "title") resEvent.setProp("title", e["title"]);
            else if (key === "start") resEvent.setStart(e["start"]);
            else if (key === "id") noop();
            else if (key === "color") noop();
            else resEvent.setExtendedProp(key, e[key]);
          });

          updatedEvent.event = resEvent;
          setEventData(updatedEvent);
        }
      });
    }
    // eslint-disable-next-line
  }, [events]);

  useEffect(() => {
    const databaseQuery = firebase.query(
      firebase.collection(firebase.db, "deletedEvents"),
      firebase.orderBy("start")
    );
    const unsub = firebase.onSnapshot(databaseQuery, (querySnapshot) => {
      const documents = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        if (data.id !== "-PLACEHOLDER") {
          documents.push(data);
        }
      });
      setDeletedEvents([...documents]);
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  const [clientAutoFill, setClientAutoFill] = useState([]);
  useEffect(() => {
    const databaseQuery = firebase.query(
      firebase.collection(firebase.db, "clientAutoFill")
    );

    const unsub = firebase.onSnapshot(databaseQuery, (querySnapshot) => {
      const documents = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        documents.push(data);
      });
      setClientAutoFill([...documents]);
    });
    return () => {
      unsub();
    };
  }, [firebase]);

  // const parseColour = (type) => {
  //   if (type === "Photography") {
  //     return "hsla(220, 50%, 60%, 0.8)";
  //   } else if (type === "Photo Assistant") {
  //     return "hsla(220, 30%, 40%, 0.8)";
  //   } else if (type === "Videography") {
  //     return "hsla(270, 50%, 60%, 0.8)";
  //   } else if (type === "Video Assistant") {
  //     return "hsla(270, 30%, 40%, 0.8)";
  //   } else if (type === "Video Editing") {
  //     return "hsla(170, 50%, 60%, 0.8)";
  //   } else if (type === "Retouching") {
  //     return "hsla(170, 30%, 40%, 0.8)";
  //   } else if (type === "Coding") {
  //     return "hsla(130, 50%, 60%, 0.8)";
  //   } else if (type === "Misc") {
  //     return "hsla(130, 30%, 40%, 0.8)";
  //   } else {
  //     return null;
  //   }
  //   // hsla(170, 30%, 20%, 0.8)
  //   // hsla(220, 30%, 40%, 0.8)
  // };

  const [showWeekends, setShowWeekends] = useState(true);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const height = use100vh();

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("sm"));

  useEffect(() => {
    if (calendarRef) {
      if (!isMobile) {
        calendarRef.current.getApi().changeView("timeGridWeek");
      } else {
        calendarRef.current.getApi().changeView("timeGridDay");
      }
    }
  }, [isMobile, calendarRef]);

  const handleEventDrop = (info) => {
    let end;
    if (!info.event.end) {
      end = info.event.start;
      end.setMinutes(60);
    } else end = info.event.endStr;
    const eventUpdated = {
      title: info.event.title,
      start: new Date(info.event.startStr).toISOString(),
      end: new Date(end).toISOString(),
      allDay: info.event.allDay,
    };
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", info.event.id),
      eventUpdated
    );
  };
  const handleEventResize = (info) => {
    handleEventDrop(info);
  };
  const createNewEvent = async (event) => {
    calendarRef.current.getApi().unselect();
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const startUNIX = startDate.getTime() / 1000;
    const endUNIX = endDate.getTime() / 1000;

    if (endUNIX - startUNIX === 900) if (!event.synthetic) return;

    const newEvent = {
      allDay: event.allDay,
      end: new Date(event.endStr).toISOString(),
      start: new Date(event.startStr).toISOString(),
      title: "New Event",
    };

    firebase.addDoc(firebase.collection(firebase.db, "events"), newEvent);
  };
  const deleteEvent = async () => {
    try {
      handleClose();
    } catch (e) {}
    const eventDataFromModal = { ...eventData };
    setShowEventModal(false);

    const eventDataRef = await firebase.getDoc(
      firebase.doc(firebase.db, "events", eventDataFromModal.event.id)
    );
    const eventDataRaw = eventDataRef.data();
    await firebase.addDoc(
      firebase.collection(firebase.db, "deletedEvents"),
      eventDataRaw
    );
    await firebase.deleteDoc(
      firebase.doc(firebase.db, "events", eventDataFromModal.event.id)
    );
  };
  const desktopToolbar = {
    left: "prev,today,next dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    right: "openSideBar",
  };
  const mobileToolbar = {
    left: "prev,next",
    center: "title",
    right: "openSideBar",
  };
  const parseCalendarWidth = () => {
    // if (showSidebar) {
    //   if (!isMobile) {
    //     // return "calc(100% - 20px - 30vw)";
    //   }
    // } else return "calc(100% - 20px)";
    return "calc(100% - 20px)";
  };
  const scrollToTopOfEventsInView = (events, startRaw, endRaw) => {
    let earliest = null;
    events.forEach((event) => {
      if (event.start > startRaw && event.start < endRaw) {
        const start = DateTime.fromISO(event.start)
          .setZone("Australia/Sydney")
          .minus({ minutes: 30 });
        const locale = start.toLocaleString(DateTime.TIME_24_SIMPLE);
        if (!earliest) earliest = locale;
        if (locale < earliest) earliest = locale;
      }
    });

    if (!earliest) return;
    calendarRef?.current?.getApi().scrollToTime(earliest);
  };
  const [contextMenu, setContextMenu] = useState(null);
  const handleContextMenu = (e, event) => {
    e.preventDefault();
    setEventData(event);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6,
          }
        : null
    );
  };
  const handleClose = () => {
    setEventData({});
    setContextMenu(null);
  };

  return (
    <>
      <div
        style={{
          color: theme.isDarkMode
            ? theme.darkModeValues.darkModeTextColour
            : theme.darkModeValues.lightModeTextColour,
          width: parseCalendarWidth(),
          margin: 10,
        }}
        {...swipeHandlers}
      >
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={deleteEvent}>Delete Event</MenuItem>
        </Menu>
        <FullCalendar
          height={`calc(${height}px - 90px)`}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            rrulePlugin,
          ]}
          ref={calendarRef}
          droppable={true}
          initialView="dayGridMonth"
          longPressDelay={300}
          scrollTime="07:30:00"
          slotDuration="00:30:00"
          eventDidMount={(event) => {
            event.el.setAttribute("tabindex", -1);

            event.el.addEventListener("contextmenu", (e) => {
              handleContextMenu(e, event);
            });
            // console.log(event);

            event.el.style.marginTop = event.event.allDay ? "0.6px" : "2.5px";
            event.el.style.marginBottom = event.event.allDay
              ? "0.6px"
              : "2.5px";
            event.el.style.marginLeft = "1.5px";
            event.el.style.marginRight = "1.5px";

            event.el.style.borderRadius = "5px";
          }}
          eventContent={(e) => {
            const today = DateTime.now()
              .setZone("Australia/Sydney")
              .startOf("day")
              .toISO();
            const start = DateTime.fromISO(
              new Date(e.event.start).toISOString()
            )
              .setZone("Australia/Sydney")
              .startOf("day")
              .toISO();
            const difference = getDaysDifference(today, start);
            // console.log(e.event);

            return (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "0.85em",
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  {/* <span>{e.timeText.replace(/ -.* /, "")}</span> */}
                  <span>
                    {e?.event?.extendedProps?.type
                      ?.toLowerCase()
                      .includes("birthday")
                      ? parseBirthday(e.event.start, e)
                      : ""}
                  </span>
                  {difference.toString().replace("-", "")} day
                  {difference === 1 || difference === -1 ? "" : "s"}
                  {difference < 0 ? " ago" : ""}
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {e.event.extendedProps.eventHasInvoice
                    ? `${
                        e.event.extendedProps.invoiceNumber
                          ? `${e.event.extendedProps.invoiceNumber} - `
                          : ""
                      }`
                    : ""}
                  {!e?.event?.extendedProps?.type
                    ?.toLowerCase()
                    .includes("birthday")
                    ? e.event.title
                    : `🎉 ${e.event.title}'s Birthday 🎉`}
                </span>
                <span style={{ fontStyle: "italic" }}>
                  {!e?.event?.extendedProps?.type
                    ?.toLowerCase()
                    .includes("birthday")
                    ? e.event.extendedProps.type
                    : ""}
                </span>
              </div>
            );
          }}
          datesSet={(info) => {
            // console.log(info);
            let start = new Date(info.start);
            let end = new Date(info.end);
            start.setDate(start.getDate(start) - 1);
            end.setDate(end.getDate(end) + 1);
            setStartDate(getFilterDate(start));
            setEndDate(getFilterDate(end));
            const divStart = new Date(info.startStr).toISOString();
            const divEnd = new Date(info.endStr).toISOString();
            setDatesInViewStart(divStart);
            setDatesInViewEnd(divEnd);
            scrollToTopOfEventsInView(events, divStart, divEnd);
          }}
          locale={enAu}
          selectLongPressDelay={300}
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
            list: "List",
          }}
          customButtons={{
            toggleWeekends: {
              text: "Weekends",
              click: () => {
                setShowWeekends(!showWeekends);
              },
            },
            openSideBar: {
              text: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 24,
                    width: 16,
                  }}
                >
                  <MenuIcon sx={{ fontSize: 25 }} />
                </div>
              ),
              click: () => {
                setShowSidebar(!showSidebar);
              },
            },
          }}
          headerToolbar={isMobile ? mobileToolbar : desktopToolbar}
          selectable={true}
          selectMirror={true}
          weekends={showWeekends}
          nowIndicator={true}
          events={events}
          editable={true}
          eventDrop={handleEventDrop}
          eventClick={async (info) => {
            calendarRef.current.getApi().unselect();
            setEventData({ ...info });
            setShowEventModal(true);
            setLayoutMenuDisabled(true);
          }}
          eventResize={handleEventResize}
          select={createNewEvent}
        />
      </div>
      <>
        <Drawer
          variant={!isMobile ? "temporary" : "persistent"}
          anchor="left"
          open={showEventModal}
          onClose={() => {
            setShowEventModal(false);
            setLayoutMenuDisabled(false);
          }}
        >
          <EventDrawer
            firebase={firebase}
            isMobile={isMobile}
            setShowEventModal={setShowEventModal}
            setLayoutMenuDisabled={setLayoutMenuDisabled}
            eventTypes={Object.keys(eventTypes)}
            eventData={eventData}
            setEventData={setEventData}
            deleteEvent={deleteEvent}
            clientAutoFill={clientAutoFill}
          />
        </Drawer>

        <Drawer
          variant={!isMobile ? "temporary" : "persistent"}
          anchor="right"
          open={showSidebar}
          onClose={() => {
            setShowSidebar(false);
          }}
        >
          <DeletedEvents
            deletedEvents={deletedEvents}
            isMobile={isMobile}
            calendarRef={calendarRef}
            setShowSidebar={setShowSidebar}
            setShowWeekends={setShowWeekends}
            showWeekends={showWeekends}
          />
        </Drawer>
      </>
    </>
  );
};
export default Calendar;

function parseBirthday(birthday, e) {
  const bday = new Date(e.event.extendedProps.startOrig);
  const event = new Date(birthday);
  const eventYear = event.getFullYear();
  const bdayYear = bday.getFullYear();
  const age = eventYear - bdayYear;
  return `${age}${nthNumber(age)}`;
}
function getFilterDate(date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
}
function getDaysDifference(startDateISOString, endDateISOString) {
  const startDate = DateTime.fromISO(startDateISOString);
  const endDate = DateTime.fromISO(endDateISOString);

  const diff = endDate.diff(startDate, "days").toObject();

  return Math.floor(diff.days);
}
const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
