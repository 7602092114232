import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import { useTheme } from '../../ThemeContext';

const darkMode = '#121212'
const lightMode = '#f4f5f7'


const LoadingFullScreen = ({ loadingVisible }) => {
    const theme = useTheme()

    const classes = makeStyles({
        loadingContainer: {
            display: loadingVisible ? 'block' : 'none',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
            backgroundColor: theme.isDarkMode ? darkMode : lightMode
        },
    })()
    const loading = {
        box: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        progress: {
            width: 90,
            height: 90,
        }
    }
    return (
        <div className={classes.loadingContainer}>
            <Box sx={loading.box}>
                <CircularProgress style={loading.progress} />
            </Box>
        </div>
    )
}

export default LoadingFullScreen
