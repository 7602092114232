import React, { useState, useEffect } from "react";

import Logo from "../images/logo.png";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import UserCard from "./login/UserCard";
import LoadingFullScreen from "./login/LoadingFullScreen";

//Imports custom firebase hook, exposing firebase functions and states
import { useFirebase } from "../FirebaseContext";
//Imports custom theme hook, exposing custom theme functions and states
import { useTheme } from "../ThemeContext";

const Login = () => {
  const firebase = useFirebase();
  const theme = useTheme();
  //updates the Meta Theme colour, in safari this changes the top section to the selected colour (Looks cool)
  theme.updateMetaThemeColour({
    light: theme.darkModeValues.lightModeBackground,
    dark: theme.darkModeValues.darkModeBackground,
  });

  const [loadingVisible, setLoadingVisible] = useState(false);
  const [usersData, setUsersData] = useState(
    localStorage.getItem("usersData")
      ? JSON.parse(localStorage.getItem("usersData"))
      : []
  );
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function AlertFunc(props) {
    return <Alert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    const init = async () => {
      setLoadingVisible(true);
      const res = await firebase.returnUserEmails();
      localStorage.setItem("usersData", JSON.stringify(res.data));
      setUsersData(res.data);
      const timeout = setTimeout(() => {
        setLoadingVisible(false);
      }, 50);
      return timeout;
    };
    const timeout = init();
    return () => clearTimeout(timeout);
  }, [setUsersData, firebase]);

  const userCardsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    maxWidth: "70vw",
  };
  return (
    <>
      <LoadingFullScreen loadingVisible={loadingVisible} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "100%",
          background: theme.isDarkMode
            ? theme.darkModeValues.darkModeBackground
            : theme.darkModeValues.lightModeBackground,
        }}
      >
        <img
          style={{ width: 140, marginTop: 80, marginBottom: 60 }}
          src={Logo}
          alt="ViDesk"
        />
        <div style={{ height: "500px", overflow: "auto" }}>
          <div style={userCardsContainerStyle}>
            {usersData.map((user, index) => (
              <UserCard
                key={`UserCard-${index}`}
                user={user}
                setLoadingVisible={setLoadingVisible}
              />
            ))}
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <AlertFunc onClose={handleClose} severity="error">
          An Error occurred, please try again later or contact the
          Administrator.
        </AlertFunc>
      </Snackbar>
    </>
  );
};

export default Login;
