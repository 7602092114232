import React, { useState, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Slide, Modal } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ReactDOMServer from "react-dom/server";
import { jsPDF } from "jspdf";
import Invoice from "../Invoice";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import debounce from "lodash.debounce";
import { use100vh } from "react-div-100vh";
import Autocomplete from "@mui/material/Autocomplete";

const { DateTime } = require("luxon");

const InvoiceDataTab = ({ firebase, eventData, isMobile, clientAutoFill }) => {
  const height = use100vh();
  const [eventHasInvoice, setEventHasInvoice] = useState(
    eventData?.event?.extendedProps?.eventHasInvoice || false
  );
  const [billedName, setBilledName] = useState(
    eventData?.event?.extendedProps?.billedName || ""
  );
  const handleClientChange = (e, newValue) => {
    setBilledName(newValue.billedName);
    setContactName(newValue.contactName);
    setBilledEmail(newValue.billedEmail);
    setCompanyName(newValue.company);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      {
        billedName: newValue.billedName,
        contactName: newValue.contactName,
        billedEmail: newValue.billedEmail,
        companyName: newValue.company,
      }
    );
  };
  // const handleBilledNameChange = (e, newValue) => {
  //   setBilledName(newValue.billedName);
  //   firebase.updateDoc(
  //     firebase.doc(firebase.db, "events", eventData.event.id),
  //     { billedName: newValue.billedName }
  //   );
  // };
  // const handleBilledNameChange = (e) => {
  //   setBilledName(e.target.value);
  //   firebase.updateDoc(
  //     firebase.doc(firebase.db, "events", eventData.event.id),
  //     { billedName: e.target.value }
  //   );
  // };
  const [contactName, setContactName] = useState(
    eventData?.event?.extendedProps?.contactName || ""
  );
  // const handleContactNameChange = (e, newValue) => {
  //   setContactName(newValue.contactName);
  //   firebase.updateDoc(
  //     firebase.doc(firebase.db, "events", eventData.event.id),
  //     { contactName: newValue.contactName }
  //   );
  // };
  const [billedEmail, setBilledEmail] = useState(
    eventData?.event?.extendedProps?.billedEmail || ""
  );
  // const handleBilledEmailChange = (e, newValue) => {
  //   setBilledEmail(newValue.billedEmail);
  //   firebase.updateDoc(
  //     firebase.doc(firebase.db, "events", eventData.event.id),
  //     { billedEmail: newValue.billedEmail }
  //   );
  // };
  const [companyName, setCompanyName] = useState(
    eventData?.event?.extendedProps?.companyName || ""
  );
  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { companyName: e.target.value }
    );
  };
  const [streetAddress, setStreetAddress] = useState(
    eventData?.event?.extendedProps?.streetAddress || ""
  );
  const handleStreetAddressChange = (e) => {
    setStreetAddress(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { streetAddress: e.target.value }
    );
  };
  const [invoiceIsPaid, setInvoiceIsPaid] = useState(
    eventData?.event?.extendedProps?.invoiceIsPaid || false
  );
  const handleInvoiceIsPaidChange = (e) => {
    setInvoiceIsPaid(e.target.checked);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { invoiceIsPaid: e.target.checked }
    );
  };
  const [clientPaysSuperannuation, setClientPaysSuperannuation] = useState(
    eventData?.event?.extendedProps?.clientPaysSuperannuation || false
  );
  const handleClientPaysSuperannuationChange = (e) => {
    setClientPaysSuperannuation(e.target.checked);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { clientPaysSuperannuation: e.target.checked }
    );
  };
  const [invoiceNotes, setInvoiceNotes] = useState(
    eventData?.event?.extendedProps?.invoiceNotes || ""
  );
  const handleInvoiceNotesChange = (e) => {
    setInvoiceNotes(e.target.value);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { invoiceNotes: e.target.value }
    );
  };
  const [billingItems, setBillingItems] = useState(
    eventData?.event?.extendedProps?.billingItems || []
  );
  const [invoiceDateOfIssue, setInvoiceDateOfIssue] = useState(
    eventData?.event?.extendedProps?.invoiceDateOfIssue || null
  );
  const handleInvoiceDateOfIssueChange = (e) => {
    let val = null;
    if (e) val = new Date(e).toISOString();

    setInvoiceDateOfIssue(val);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { invoiceDateOfIssue: val }
    );
  };
  const [invoiceDueDate, setInvoiceDueDate] = useState(
    eventData?.event?.extendedProps?.invoiceDueDate || null
  );
  const handleInvoiceDueDateChange = (e) => {
    let val = null;
    if (e) val = new Date(e).toISOString();
    setInvoiceDueDate(val);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { invoiceDueDate: val }
    );
  };
  const [invoiceNumber, setInvoiceNumber] = useState(
    eventData?.event?.extendedProps?.invoiceNumber || ""
  );
  const handleInvoiceNumberChange = (e) => {
    let val = "";
    try {
      val = parseInt(e.target.value);
    } catch (e) {}
    setInvoiceNumber(val);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      {
        invoiceNumber: val,
      }
    );
  };
  const [showPersonalDetails, setShowShowPersonalDetails] = useState(
    eventData?.event?.extendedProps?.showPersonalDetails || false
  );
  const handleShowPersonalDetailsChange = (e) => {
    setShowShowPersonalDetails(e.target.checked);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      {
        showPersonalDetails: e.target.checked,
      }
    );
  };
  const [invoiceSent, setInvoiceSent] = useState(
    eventData?.event?.extendedProps?.invoiceSent || false
  );
  useEffect(() => {
    setInvoiceSent(eventData?.event?.extendedProps?.invoiceSent || "");
    setBilledName(eventData?.event?.extendedProps?.billedName || "");
    setContactName(eventData?.event?.extendedProps?.contactName || "");
    setBilledEmail(eventData?.event?.extendedProps?.billedEmail || "");
    setCompanyName(eventData?.event?.extendedProps?.companyName || "");
    setStreetAddress(eventData?.event?.extendedProps?.streetAddress || "");
    setEventHasInvoice(
      eventData?.event?.extendedProps?.eventHasInvoice || false
    );
    setInvoiceIsPaid(eventData?.event?.extendedProps?.invoiceIsPaid || false);
    setClientPaysSuperannuation(
      eventData?.event?.extendedProps?.clientPaysSuperannuation || false
    );
    setInvoiceNotes(eventData?.event?.extendedProps?.invoiceNotes || "");
    setBillingItems(eventData?.event?.extendedProps?.billingItems || []);
    setInvoiceDateOfIssue(
      eventData?.event?.extendedProps?.invoiceDateOfIssue || null
    );
    setInvoiceDueDate(eventData?.event?.extendedProps?.invoiceDueDate || null);
    setInvoiceNumber(eventData?.event?.extendedProps?.invoiceNumber || "");
    setShowShowPersonalDetails(
      eventData?.event?.extendedProps?.showPersonalDetails || false
    );
  }, [eventData]);
  const cloneLocationFromEvent = () => {
    let e = { target: { value: eventData?.event?.extendedProps?.location } };
    handleStreetAddressChange(e);
  };
  const buttonGroupContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  };
  const [saveInvoiceIsLoading, setSaveInvoiceIsLoading] = useState(false);
  // eslint-disable-next-line
  const [sendInvoiceIsLoading, setSendInvoiceIsLoading] = useState(false);

  const generatePDF = (kind, resolve, reject) => {
    if (kind === "file") setSaveInvoiceIsLoading(true);
    try {
      let invoiceDateOfIssueConv =
        DateTime.fromISO(invoiceDateOfIssue).setZone("Australia/Sydney");
      let invoiceDateOfIssueLocale = invoiceDateOfIssueConv.toLocaleString(
        DateTime.DATE_SHORT
      );
      let invoiceDueDateConv;
      let invoiceDueDateLocale;
      if (invoiceDueDate) {
        invoiceDueDateConv =
          DateTime.fromISO(invoiceDueDate).setZone("Australia/Sydney");
        invoiceDueDateLocale = invoiceDueDateConv.toLocaleString(
          DateTime.DATE_SHORT
        );
      }

      const ele = ReactDOMServer.renderToString(
        <Invoice
          invoiceDateOfIssue={invoiceDateOfIssueLocale}
          invoiceDueDate={invoiceDueDateLocale}
          billedName={billedName}
          billedEmail={billedEmail}
          companyName={companyName}
          streetAddress={streetAddress}
          invoiceNumber={invoiceNumber}
          invoiceIsPaid={invoiceIsPaid}
          invoiceNotes={invoiceNotes}
          clientPaysSuperannuation={clientPaysSuperannuation}
          billingItems={billingItems}
          showPersonalDetails={showPersonalDetails}
        />
      );
      const pdf = new jsPDF({
        orientation: "portrait",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 16,
        floatPrecision: "smart",
      });
      pdf.setFont("Helvetica", "normal");
      pdf.html(ele, {
        callback: (docWithHtml) => {
          docWithHtml.setFont("Helvetica", "normal");
          if (kind === "base64") {
            const inv = docWithHtml.output(
              "datauristring",
              `${invoiceNumber}.pdf`
            );
            resolve(inv);
          }
          if (kind === "file") docWithHtml.save(`${invoiceNumber}.pdf`);
          if (kind === "file") setSaveInvoiceIsLoading(false);
        },
        width: 210,
        windowWidth: 840,
      });
    } catch (e) {
      console.log(e);
      if (reject) {
        reject(e);
      }
      setSaveInvoiceIsLoading(false);
    }
  };
  const invoiceButtonStyle = {
    width: 176,
    // height: 40,
  };
  const createInvoice = () => {
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { eventHasInvoice: true }
    );
  };
  const billingItemsTitleStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    marginBottom: -15,
  };
  const addNewBillingItem = () => {
    billingItems.push({
      description: "",
      qtyHrs: "",
      unitPrice: "",
    });
    setBillingItems([...billingItems]);
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { billingItems: billingItems }
    );
  };
  const [showDeleteBillingItemButton, setShowDeleteBillingItemButton] =
    useState(false);
  const toggleShowDeleteBillingItemButton = () => {
    setShowDeleteBillingItemButton(!showDeleteBillingItemButton);
  };
  const copyBillingItemsToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(billingItems));
  };
  const pasteBillingItemsFromClipboard = async () => {
    try {
      const bItems = JSON.parse(await navigator.clipboard.readText());
      setBillingItems([...bItems]);
      firebase.updateDoc(
        firebase.doc(firebase.db, "events", eventData.event.id),
        { billingItems: bItems }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const emailContainerMainStyle = {
    zIndex: 100,
    height: "100%",
  };
  const emailContainerStyle = {
    // width: "100%",
    margin: 15,
    marginTop: 20,
    position: "relative",
  };

  // const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  // const [replyToEmails, setReplyToEmails] = useState([]);
  const [subject, setSubject] = useState("Invoice");
  // eslint-disable-next-line
  const [ccVisible, setCcVisible] = useState(false);
  // eslint-disable-next-line
  const [bccVisible, setBccVisible] = useState(false);
  const [emailBody, setEmailBody] = useState(`Hi${
    contactName ? ` ${contactName}` : billedName ? ` ${billedName}` : ""
  },

Please see attached my latest invoice

Alex`);
  useEffect(() => {
    setEmailBody(`Hi${
      contactName ? ` ${contactName}` : billedName ? ` ${billedName}` : ""
    },

Please see attached my latest invoice
  
Alex`);
  }, [contactName, billedName]);

  // const toggleCC = () => setCcVisible(!ccVisible);
  // const toggleBCC = () => setBccVisible(!bccVisible);
  // const fromEmail = "info@alexmorrisseysmith.com";

  const [gettingInvoiceNumber, setGettingInvoiceNumber] = useState(false);

  const findClient = (option, clientAutoFill, kind) => {
    if (option[kind]) return `${option[kind]}`;
    for (let i = 0; i < clientAutoFill.length; i++) {
      if (clientAutoFill[i][kind] === option) {
        return `${clientAutoFill[i][kind]}`;
      }
    }

    return `No Company - ${option.billedEmail}`;
  };

  const [makeNewClientModalOpen, setMakeNewClientModalOpen] = useState(false);
  const [newClientBilledName, setNewClientBilledName] = useState("");
  const [newClientBilledEmail, setNewClientBilledEmail] = useState("");
  const [newClientContactName, setNewClientContactName] = useState("");
  const [newClientCompany, setNewClientCompany] = useState("");
  const [preview, setPreview] = useState("");
  const makeNewClient = async () => {
    if (!newClientBilledName) {
      alert("Enter Billed Name");
      return;
    }
    if (!newClientBilledEmail) {
      alert("Enter Billed Email");
      return;
    }
    if (!newClientContactName) {
      alert("Enter Contact Name");
      return;
    }
    const obj = {
      billedName: newClientBilledName,
      billedEmail: newClientBilledEmail,
      contactName: newClientContactName,
      company: newClientCompany,
    };
    await firebase.addDoc(
      firebase.collection(firebase.db, "clientAutoFill"),
      obj
    );
    setMakeNewClientModalOpen(false);
    setNewClientBilledName("");
    setNewClientBilledEmail("");
    setNewClientContactName("");
    setNewClientCompany("");
  };
  return (
    <>
      <Modal
        open={emailModalOpen}
        onClose={() => {
          setEmailModalOpen(false);
        }}
        closeAfterTransition
      >
        <Slide
          direction="up"
          in={emailModalOpen}
          mountOnEnter
          unmountOnExit
          timeout={200}
        >
          <div
            id="emailModalBG"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: height,
            }}
            onClick={(e) => {
              if (e.target.id === "emailModalBG") {
                setEmailModalOpen(false);
              }
            }}
          >
            <div
              style={{
                width: isMobile ? "100vw" : 600,
                height: isMobile ? height : height - 20,
                borderRadius: isMobile ? 0 : 6,
                background: "#1f1f1f",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  display: isMobile ? "block" : "none",
                  margin: 5,
                  paddingTop: 5,
                  paddingBottom: 15,
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 10,
                  }}
                  onClick={() => {
                    setEmailModalOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div style={emailContainerMainStyle}>
                <div style={emailContainerStyle}>
                  <EmailField
                    emails={[{ email: billedEmail }]}
                    setEmails={false}
                    kind={"To"}
                    visible={true}
                    marginRight={20}
                  />
                  <Divider />
                  <EmailField
                    emails={ccEmails}
                    setEmails={setCcEmails}
                    kind={"Cc"}
                    visible={ccVisible}
                    marginRight={0}
                  />
                  <Divider style={{ display: ccVisible ? "flex" : "none" }} />
                  <EmailField
                    emails={bccEmails}
                    setEmails={setBccEmails}
                    kind={"Bcc"}
                    visible={bccVisible}
                    marginRight={0}
                  />
                  <Divider style={{ display: bccVisible ? "flex" : "none" }} />
                  <div
                    style={{
                      width: "100%",
                      marginTop: 5,
                      marginBottom: 5,
                      display: "flex",
                      justifyContent: "space-between",
                      // justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          marginRight: 10,
                          fontSize: "0.8em",
                        }}
                      >
                        Subject:
                      </p>
                      <TextField
                        size="small"
                        variant="standard"
                        sx={{
                          width: "100%",
                        }}
                        inputProps={{
                          sx: {
                            marginTop: "5px",
                            height: 20,
                            fontSize: "14px",
                          },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={subject || ""}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                    </div>
                    {/* <ButtonGroup
                      size="small"
                      style={{ height: 24, marginRight: 25 }}
                    >
                      <Button
                        variant={ccVisible ? "contained" : "outlined"}
                        onClick={toggleCC}
                      >
                        CC
                      </Button>
                      <Button
                        variant={bccVisible ? "contained" : "outlined"}
                        onClick={toggleBCC}
                      >
                        BCC
                      </Button>
                    </ButtonGroup> */}
                  </div>
                  {/* <Divider />
                  <EmailField
                    emails={replyToEmails}
                    setEmails={setReplyToEmails}
                    kind={"Reply To"}
                    visible={true}
                    marginRight={0}
                  /> */}
                  <Divider />
                  <TextField
                    // label="Invoice Notes"
                    variant="standard"
                    sx={{
                      width: "100%",
                    }}
                    inputProps={{
                      sx: {
                        marginTop: "5px",
                        height: 20,
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{ disableUnderline: true }}
                    multiline
                    fullWidth
                    rows={5}
                    value={emailBody}
                    onChange={(e) => {
                      setEmailBody(e.target.value);
                    }}
                  />
                </div>
                <iframe
                  style={{ border: "none", width: "100%", height: "100%" }}
                  src={preview}
                />
                <Fab
                  color="primary"
                  style={{
                    width: "50px",
                    height: "50px",
                    zIndex: 200,
                    position: "absolute",
                    bottom: 14,
                    right: 14,
                  }}
                  onClick={async () => {
                    if (sendInvoiceIsLoading) {
                      console.log("nope");
                      return;
                    }
                    try {
                      setSendInvoiceIsLoading(true);
                      const inv = await new Promise((resolve, reject) => {
                        generatePDF("base64", resolve, reject);
                      });
                      await firebase.sendEmail({
                        emailBody: emailBody,
                        fromEmail: "info@alexmorrisseysmith.com",
                        toEmails: [billedEmail],
                        ccEmails: [""],
                        bccEmails: [""],
                        replyToEmails: [""],
                        subject,
                        attachments: [
                          {
                            filename: `${invoiceNumber}.pdf`,
                            path: inv,
                          },
                        ],
                      });
                      setEmailModalOpen(false);
                      setInvoiceSent(true);
                      firebase.updateDoc(
                        firebase.doc(firebase.db, "events", eventData.event.id),
                        {
                          invoiceSent: true,
                        }
                      );
                      setSendInvoiceIsLoading(false);
                    } catch (e) {
                      setSendInvoiceIsLoading(false);
                    }
                  }}
                >
                  {sendInvoiceIsLoading ? (
                    <CircularProgress size={30} style={{ color: "black" }} />
                  ) : (
                    <SendIcon
                      sx={{ marginLeft: "4px", width: "30px", height: "30px" }}
                    />
                  )}
                </Fab>
              </div>
            </div>
          </div>
        </Slide>
      </Modal>

      <Modal
        open={makeNewClientModalOpen}
        onClose={() => {
          setMakeNewClientModalOpen(false);
          setNewClientBilledName("");
          setNewClientBilledEmail("");
          setNewClientContactName("");
          setNewClientCompany("");
        }}
        closeAfterTransition
      >
        <Slide
          direction="up"
          in={makeNewClientModalOpen}
          mountOnEnter
          unmountOnExit
          timeout={200}
        >
          <div
            id="emailModalBG"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: height,
            }}
            onClick={(e) => {
              if (e.target.id === "emailModalBG") {
                setMakeNewClientModalOpen(false);
                setNewClientBilledName("");
                setNewClientBilledEmail("");
                setNewClientContactName("");
                setNewClientCompany("");
              }
            }}
          >
            <div
              style={{
                width: isMobile ? "100vw" : 400,
                height: isMobile ? height : 450,
                background: "rgb(35, 35, 35)",
                borderRadius: 5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  display: isMobile ? "block" : "none",
                  margin: 5,
                  paddingTop: 5,
                  paddingBottom: 15,
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 10,
                  }}
                  onClick={() => {
                    setEmailModalOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 30,
                  paddingTop: 40,
                  paddingBottom: 30,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                <TextField
                  label="Billed Name*"
                  value={newClientBilledName}
                  onChange={(e) => setNewClientBilledName(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  label="Contact Name*"
                  value={newClientContactName}
                  onChange={(e) => setNewClientContactName(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  label="Billed Email*"
                  value={newClientBilledEmail}
                  onChange={(e) => setNewClientBilledEmail(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  label="Company"
                  value={newClientCompany}
                  onChange={(e) => setNewClientCompany(e.target.value)}
                  variant="outlined"
                />
                <Button variant="contained" onClick={makeNewClient}>
                  Make New Client
                </Button>
              </div>
            </div>
          </div>
        </Slide>
      </Modal>
      {eventHasInvoice ? (
        <>
          <div style={buttonGroupContainerStyle}>
            <ButtonGroup variant="outlined">
              <Button
                sx={invoiceButtonStyle}
                onClick={() => {
                  generatePDF("file");
                }}
              >
                {saveInvoiceIsLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  "Save Invoice"
                )}
              </Button>
              <Button
                sx={invoiceButtonStyle}
                onClick={async () => {
                  setEmailModalOpen(true);
                  const inv = await new Promise((resolve, reject) => {
                    generatePDF("base64", resolve, reject);
                  });
                  setPreview(inv);
                }}
              >
                {sendInvoiceIsLoading ? (
                  <CircularProgress size={25} />
                ) : invoiceSent ? (
                  <>
                    <CheckIcon /> &nbsp; Send Invoice
                  </>
                ) : (
                  "Send Invoice"
                )}
              </Button>
            </ButtonGroup>
          </div>
          <Button
            size="small"
            variant="contained"
            sx={{ marginBottom: 3 }}
            onClick={() => {
              setMakeNewClientModalOpen(true);
            }}
          >
            Make New Client
          </Button>
          {/* <TextField
            sx={{ marginBottom: 2 }}
            label="Billed Name"
            value={billedName}
            onChange={handleBilledNameChange}
            variant="outlined"
            size={isMobile ? "small" : "medium"}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            label="Contact Name"
            value={contactName}
            onChange={handleContactNameChange}
            variant="outlined"
            size={isMobile ? "small" : "medium"}
          />
          <TextField
            sx={{ marginBottom: 2 }}
            label="Billed Email"
            value={billedEmail}
            onChange={handleBilledEmailChange}
            variant="outlined"
            size={isMobile ? "small" : "medium"}
          /> */}
          <Autocomplete
            sx={{ marginBottom: 2 }}
            disablePortal
            options={clientAutoFill}
            getOptionLabel={(option) =>
              findClient(option, clientAutoFill, "billedName")
            }
            onChange={handleClientChange}
            value={billedName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Billed Name"
                size={isMobile ? "small" : "medium"}
              />
            )}
          />
          <Autocomplete
            sx={{ marginBottom: 2 }}
            disablePortal
            options={clientAutoFill}
            getOptionLabel={(option) =>
              findClient(option, clientAutoFill, "contactName")
            }
            onChange={handleClientChange}
            value={contactName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Contact Name"
                size={isMobile ? "small" : "medium"}
              />
            )}
          />
          <Autocomplete
            sx={{ marginBottom: 2 }}
            disablePortal
            options={clientAutoFill}
            getOptionLabel={(option) =>
              findClient(option, clientAutoFill, "billedEmail")
            }
            onChange={handleClientChange}
            value={billedEmail || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Billed Email"
                size={isMobile ? "small" : "medium"}
              />
            )}
          />

          <TextField
            sx={{ marginBottom: 2 }}
            label="Invoice Number"
            value={invoiceNumber}
            type="number"
            onChange={handleInvoiceNumberChange}
            variant="outlined"
            size={isMobile ? "small" : "medium"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      setGettingInvoiceNumber(true);
                      try {
                        const res = await firebase.getLatestInvoiceNumber();
                        setInvoiceNumber(parseInt(res.data.jobNumber));
                        firebase.updateDoc(
                          firebase.doc(
                            firebase.db,
                            "events",
                            eventData.event.id
                          ),
                          {
                            invoiceNumber: parseInt(res.data.jobNumber),
                          }
                        );
                        setGettingInvoiceNumber(false);
                      } catch (e) {
                        setGettingInvoiceNumber(false);
                      }
                    }}
                  >
                    {gettingInvoiceNumber ? (
                      <CircularProgress size={20} />
                    ) : (
                      <PlusOneIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <Autocomplete
              sx={{ marginBottom: 2, marginRight: isMobile ? 0 : 2 }}
              disablePortal
              options={clientAutoFill}
              getOptionLabel={(option) =>
                findClient(option, clientAutoFill, "company")
              }
              onChange={handleClientChange}
              value={companyName || ""}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Company Name"
                  size={isMobile ? "small" : "medium"}
                />
              )}
            />
            {/* <TextField
              sx={{ marginBottom: 2, marginRight: isMobile ? 0 : 2 }}
              label="Company Name"
              value={companyName}
              onChange={handleCompanyNameChange}
              variant="outlined"
              size={isMobile ? "small" : "medium"}
              fullWidth
            /> */}
            <TextField
              sx={{ marginBottom: 2, marginLeft: isMobile ? 0 : 2 }}
              label="Street Address"
              value={streetAddress}
              onChange={handleStreetAddressChange}
              variant="outlined"
              size={isMobile ? "small" : "medium"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={cloneLocationFromEvent}>
                      <ReplayIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of Issue"
                value={invoiceDateOfIssue}
                inputVariant="outlined"
                onChange={handleInvoiceDateOfIssueChange}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size={isMobile ? "small" : "medium"}
                    sx={{ marginBottom: 2, marginRight: isMobile ? 0 : 2 }}
                    {...params}
                  />
                )}
                inputFormat="dd/MM/yy"
              />
              <DatePicker
                label="Due Date"
                value={invoiceDueDate}
                inputVariant="outlined"
                onChange={handleInvoiceDueDateChange}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size={isMobile ? "small" : "medium"}
                    sx={{ marginBottom: 2, marginLeft: isMobile ? 0 : 2 }}
                    {...params}
                  />
                )}
                inputFormat="dd/MM/yy"
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              marginBottom: 0,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={invoiceIsPaid}
                  onChange={handleInvoiceIsPaidChange}
                />
              }
              label="Invoice is Paid"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={clientPaysSuperannuation}
                  onChange={handleClientPaysSuperannuationChange}
                />
              }
              label="Client Pays Superannuation"
            />
          </div>
          {clientPaysSuperannuation ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPersonalDetails}
                    onChange={handleShowPersonalDetailsChange}
                  />
                }
                sx={{ marginBottom: 2 }}
                label="Show Personal Details"
              />
            </>
          ) : null}
          <TextField
            label="Invoice Notes"
            multiline
            rows={4}
            value={invoiceNotes}
            onChange={handleInvoiceNotesChange}
          />
          <div>
            <div style={billingItemsTitleStyle}>
              <p>Billing Items</p>
              <span>
                <IconButton onClick={copyBillingItemsToClipboard}>
                  <ContentCopyIcon />
                </IconButton>
                <IconButton
                  sx={{ marginRight: 4 }}
                  onClick={pasteBillingItemsFromClipboard}
                >
                  <ContentPasteGoIcon />
                </IconButton>
                <IconButton
                  sx={{ marginRight: 1 }}
                  onClick={toggleShowDeleteBillingItemButton}
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={addNewBillingItem}>
                  <AddIcon />
                </IconButton>
              </span>
            </div>
            {billingItems &&
              billingItems.map((billingItem, index) => (
                <BillingItem
                  key={`billingItem-${index}`}
                  billingItem={billingItem}
                  billingItems={billingItems}
                  setBillingItems={setBillingItems}
                  firebase={firebase}
                  index={index}
                  eventData={eventData}
                  showDeleteBillingItemButton={showDeleteBillingItemButton}
                />
              ))}
          </div>
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            sx={{ marginTop: 4, height: 50 }}
            onClick={createInvoice}
          >
            Create Invoice
          </Button>
        </>
      )}
    </>
  );
};

export default InvoiceDataTab;

const BillingItem = ({
  billingItem,
  index,
  billingItems,
  setBillingItems,
  firebase,
  eventData,
  showDeleteBillingItemButton,
}) => {
  const updateFirebase = (key, value) => {
    firebase.updateDoc(
      firebase.doc(firebase.db, "events", eventData.event.id),
      { [key]: value }
    );
  };
  const updateFirebaseDebounce = debounce(updateFirebase, 800);

  const [unitPrice, setUnitPrice] = useState(billingItem.unitPrice);
  const handleUnitPriceChange = (e) => {
    setUnitPrice(e.target.value);
    billingItem.unitPrice = e.target.value;
    setBillingItems([...billingItems]);
    updateFirebaseDebounce("billingItems", billingItems);
  };
  const [qtyHrs, setQtyHrs] = useState(billingItem.qtyHrs);
  const handleQtyHrsChange = (e) => {
    setQtyHrs(e.target.value);
    billingItem.qtyHrs = e.target.value;
    setBillingItems([...billingItems]);
    updateFirebaseDebounce("billingItems", billingItems);
  };
  const [description, setDescription] = useState(billingItem.description);
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    billingItem.description = e.target.value;
    setBillingItems([...billingItems]);
    updateFirebaseDebounce("billingItems", billingItems);
  };
  const deleteBillingItem = () => {
    billingItems.splice(index, 1);
    setBillingItems([...billingItems]);
    updateFirebaseDebounce("billingItems", billingItems);
  };
  useEffect(() => {
    setUnitPrice(billingItem.unitPrice);
    setQtyHrs(billingItem.qtyHrs);
    setDescription(billingItem.description);
    // eslint-disable-next-line
  }, [billingItems]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <div
          style={{
            width: 80,
            display: showDeleteBillingItemButton ? "block" : "none",
          }}
        >
          <IconButton onClick={deleteBillingItem}>
            <DeleteIcon />
          </IconButton>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ marginBottom: 2, marginRight: 2 }}
              label="Qty/Hours"
              fullWidth
              value={qtyHrs}
              onChange={handleQtyHrsChange}
              variant="outlined"
              size="small"
            />
            <TextField
              sx={{ marginBottom: 2, marginLeft: 2 }}
              label="Unit Price"
              fullWidth
              value={unitPrice}
              onChange={handleUnitPriceChange}
              variant="outlined"
              size="small"
            />
          </div>
          <TextField
            sx={{ marginBottom: 0 }}
            label="Description"
            fullWidth
            value={description}
            onChange={handleDescriptionChange}
            variant="outlined"
            size="small"
          />
        </div>
      </div>
      <Divider sx={{ marginTop: 2 }} />
    </>
  );
};

const EmailField = ({ emails, setEmails, kind, visible, marginRight }) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailVisible, setNewEmailVisible] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailButtonVisible, setNewEmailButtonVisible] = useState(true);

  const newEmailRef = useRef(newEmail);
  newEmailRef.current = newEmail;

  const handleDeleteEmailChip = (index) => {
    const tmpClients = [...emails];
    tmpClients.splice(index, 1);
    setEmails([...tmpClients]);
  };
  const handleNewEmailClick = () => {
    setNewEmailButtonVisible(false);
    setNewEmailVisible(true);
    setTimeout(() => {
      if (!newEmailRef.current) {
        setNewEmailButtonVisible(true);
        setNewEmailVisible(false);
        setNewEmailError(false);
      }
    }, 10000);
  };

  const confirmNewEmail = () => {
    if (!newEmailError) {
      setEmails([...emails, { email: newEmail }]);
      setNewEmailVisible(false);
      setNewEmailButtonVisible(true);
      setNewEmail("");
    }
  };

  const emailsContainerStyle = {
    minHeight: 10,
    display: visible ? "flex" : "none",
    marginBottom: 2,
    marginTop: 2,
    marginRight: marginRight,
    alignItems: "center",
  };
  return (
    <div style={emailsContainerStyle}>
      <p
        style={{
          marginTop: 8,
          marginBottom: 8,
          marginRight: 10,
          fontSize: "0.8em",
        }}
      >
        {kind}:
      </p>
      <div style={{ marginTop: 0 }}>
        <Stack direction="row" spacing={1}>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {emails &&
              emails.map((toEmail, i) => {
                if (toEmail) {
                  if (toEmail.email) {
                    return (
                      <Tooltip
                        key={`EmailChipToolTip-${i}`}
                        title={toEmail.email}
                      >
                        <Chip
                          style={{
                            margin: 2,
                            fontWeight: "bold",
                            fontSize: "0.7em",
                          }}
                          size="small"
                          key={`EmailChip-${i}`}
                          label={toEmail.name || toEmail.email}
                          onDelete={() => {
                            handleDeleteEmailChip(i);
                          }}
                        />
                      </Tooltip>
                    );
                  } else return null;
                } else return null;
              })}
            <TextField
              style={{
                marginLeft: 4,
                marginTop: 3,
                marginBottom: 2,
                display: newEmailVisible ? "block" : "none",
              }}
              size="small"
              inputRef={(input) => {
                if (input) {
                  newEmailVisible && input.focus();
                }
              }}
              inputProps={{ sx: { height: 10, width: 190, fontSize: "11px" } }}
              error={newEmailError}
              value={newEmail || ""}
              onChange={(e) => {
                setNewEmail(e.target.value);
                // eslint-disable-next-line
                if (
                  e.target.value.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  setNewEmailError(false);
                } else setNewEmailError(true);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  confirmNewEmail();
                }
              }}
            />
            {setEmails ? (
              <IconButton
                sx={{ width: 26, height: 26 }}
                style={{ display: newEmailButtonVisible ? "flex" : "none" }}
                onClick={handleNewEmailClick}
              >
                <AddCircleIcon color="primary" sx={{ width: 20 }} />
              </IconButton>
            ) : null}
          </div>
        </Stack>
      </div>
    </div>
  );
};
