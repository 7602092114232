import React from "react";
import logo from "../images/logoAMS.png";
import paid from "../images/paid.png";
import "./invoiceStyle.css";

const Invoice = ({
  invoiceDateOfIssue,
  invoiceDueDate,
  billedName,
  billedEmail,
  companyName,
  streetAddress,
  invoiceNumber,
  billingItems,
  invoiceIsPaid,
  invoiceNotes,
  clientPaysSuperannuation,
  showPersonalDetails,
}) => {
  // billingItems = [
  //   {
  //     description: "Website Coding",
  //     qtyHrs: 6,
  //     unitPrice: 35,
  //   },
  //   {
  //     description: "Website Coding 2 this is a test of a long description",
  //     qtyHrs: 6,
  //     unitPrice: 35,
  //   },
  //   {
  //     description:
  //       "Website Coding 2 this is a test of a really really really really really long description",
  //     qtyHrs: 6,
  //     unitPrice: 35,
  //   },
  // ];
  return (
    <>
      <div
        className="invoice-box"
        style={{ letterSpacing: "100px", fontFamily: "Roboto-Regular" }}
      >
        <img
          src={paid}
          alt="paid"
          style={{
            display: invoiceIsPaid ? "block" : "none",
            position: "absolute",
            width: 300,
            top: 100,
            left: "50%",
            transform: "translate(-50%)",
          }}
        />
        <table>
          <tbody>
            <tr className="top">
              <td colSpan="5">
                <table>
                  <tbody>
                    <tr>
                      <td className="title" colSpan={1}>
                        <img
                          src={logo}
                          alt="Logo"
                          style={{ width: 100, maxWidth: 300 }}
                        />
                      </td>

                      <td colSpan={2}>
                        <span style={{ color: "rgb(0,0,0)" }}>
                          <b>Morrissey Media</b>
                        </span>
                        <br />
                        <span style={{ lineHeight: 0.4 }}>
                          <p className="MMInfoSmallText">0481308335</p>
                          <p className="MMInfoSmallText">
                            17/261, King Georges Road
                          </p>
                          <p className="MMInfoSmallText">
                            Roselands, 2196, Australia
                          </p>
                          <p className="MMInfoSmallText">ABN 40551956933</p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="5" style={{ color: "rgb(0,0,0)" }}>
                <table style={{ width: 260 }}>
                  <tbody>
                    <tr>
                      <td>
                        <b>Date of Issue:</b>
                      </td>
                      <td>
                        <b>{invoiceDateOfIssue}</b>
                      </td>
                    </tr>
                    {invoiceDueDate ? (
                      <tr>
                        <td>
                          <b>Due Date:</b>
                        </td>
                        <td>
                          <b>{invoiceDueDate}</b>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span style={{ color: "rgb(0,0,0)" }}>
                          <b>Billed To:</b>
                        </span>
                        <span style={{ lineHeight: 0.6 }}>
                          <p className="invoiceBilledToSmallText">
                            {billedName}
                          </p>
                          <p className="invoiceBilledToSmallText">
                            {billedEmail}
                          </p>
                          <p className="invoiceBilledToSmallText">
                            {companyName}
                          </p>
                          <p className="invoiceBilledToSmallText">
                            {streetAddress}
                          </p>
                        </span>
                      </td>
                      <td>
                        <div style={{ margin: 50, lineHeight: 0.1 }}>
                          <p style={{ fontSize: 30, color: "rgb(0,0,0)" }}>
                            <b>Invoice #</b>
                          </p>
                          <p>{invoiceNumber}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="heading">
              <td colSpan={2}>Description</td>
              <td>Qty/Hours</td>
              <td>Unit Price</td>
              <td>Total Price</td>
            </tr>
            {billingItems &&
              billingItems.map((billingItem, index) => {
                const lastItem =
                  index + 1 === billingItems.length ? true : false;
                return (
                  <BillingLineItem
                    key={`BillingLineItem_${index}`}
                    description={billingItem.description}
                    qtyHrs={billingItem.qtyHrs}
                    unitPrice={billingItem.unitPrice}
                    lastItem={lastItem}
                  />
                );
              })}
            <Total billingItems={billingItems} />
            {clientPaysSuperannuation ? (
              <tr>
                <td colSpan={5}>
                  <div style={{ fontSize: 10, lineHeight: 0.5 }}>
                    <p>
                      Superannuation to be paid at the rate set by the ATO at
                      the date of issue above
                    </p>
                    <p>Provider: Australian Super</p>
                    <p>Member Number: 703659367</p>
                    <p>Fund USI: STA0100AU</p>
                    {showPersonalDetails ? (
                      <>
                        <p>TFN: 406 517 619</p>
                        <p>DOB: 19/03/1996</p>
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            ) : null}

            {invoiceNotes ? (
              <tr>
                <td colSpan={5}>
                  <div style={{ fontSize: 10, lineHeight: 1.2, maxWidth: 300 }}>
                    {invoiceNotes}
                  </div>
                </td>
              </tr>
            ) : null}
            <tr>
              <td colSpan={5}>
                <div style={{ fontSize: 10, lineHeight: 0.5 }}>
                  <p>Direct Bank Deposit to:</p>
                  <p>Account: Alex Morrissey-Smith</p>
                  {/* UP ACCOUNT */}
                  {/* <p>BSB Number: 633123</p>
                  <p>Account Number: 178396529</p> */}
                  <p>BSB Number: 802985</p>
                  <p>Account Number: 415028915</p>
                  <br />
                  <p>Please include your invoice number in the</p>
                  <p>description and reference of the payment.</p>
                  <br />
                  <p>Thank you for your business!</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Invoice;

const Total = ({ billingItems }) => {
  let total = 0.0;

  if (billingItems) {
    if (billingItems.length > 0) {
      billingItems.forEach((billingItem) => {
        total = total + billingItem.unitPrice * billingItem.qtyHrs;
      });
    }
  }
  return (
    <tr className="total">
      <td colSpan={2}></td>
      <td></td>
      <td></td>
      <td>Total: ${total}</td>
    </tr>
  );
};

const BillingLineItem = ({ description, qtyHrs, unitPrice, lastItem }) => {
  const totalLinePrice = unitPrice * qtyHrs;
  return (
    <tr className={`item ${lastItem ? "last" : ""} `}>
      <td colSpan={2}>{description}</td>
      <td>{qtyHrs}</td>
      {unitPrice ? <td>${unitPrice}</td> : <td></td>}
      {unitPrice ? <td>${totalLinePrice}</td> : <td></td>}
    </tr>
  );
};

/* <tr className="heading">
              <td colSpan={2}>Description</td>
              <td>Qty/Hours</td>
              <td>Unit Price</td>
              <td>Total Price</td>
            </tr>

            <tr className="item">
              <td colSpan={2}>Website design</td>
              <td>1</td>
              <td>$300.00</td>
              <td>$300.00</td>
            </tr>

            <tr className="item">
              <td colSpan={2}>Hosting (3 months)</td>
              <td>1</td>
              <td>$75.00</td>
              <td>$75.00</td>
            </tr>

            <tr className="item">
              <td colSpan={2}>Domain name (1 year)</td>
              <td>1</td>
              <td>$10.00</td>
              <td>$10.00</td>
            </tr>

            <BillingLineItem
              description="test"
              qtyHrs={1}
              unitPrice={200}
            /> */

/* <button
  onClick={() => {
    const pdf = new jsPDF({
      orientation: "p",
      format: "a4",
      putOnlyUsedFonts: true,
    });
    const ele = ReactDOMServer.renderToString(<Invoice />);
    //   console.log(ele);
    pdf.html(ele, {
      callback: (docWithHtml) => {
        docWithHtml.save("Test.pdf");
      },
      width: 210,
      windowWidth: 860,
    });
  }}
>
  make
</button> */

// const Invoice = () => {
//     //   const headerStyle = {
//     //     display: "flex",
//     //     justifyContent: "space-between",
//     //     width: "210mm",
//     //   };
//     //   const headerInfoStyle = {
//     //     display: "flex",
//     //     flexDirection: "column",
//     //     alignItems: "flex-end",
//     //     marginTop: 4,
//     //   };
//     //   const headerInfoSubStyle = {
//     //     fontSize: 11,
//     //     marginTop: 4.8,
//     //     color: "rgba(0, 0, 0, 0.5)",
//     //   };

//     //   const infoStyle = {
//     //     display: "flex",
//     //     justifyContent: "space-between",
//     //   };
//     //   const styles = StyleSheet.create({
//     //     document: {
//     //       backgroundColor: "red",
//     //       height: "272mm !important",
//     //       width: "210mm !important",
//     //       boxSizing: "border-box",
//     //     //   padding: 110,
//     //     },
//     //     page: {
//     //       flexDirection: "row",
//     //       fontFamily: "Roboto-Regular",
//     //     },
//     //     section: {
//     //       //   margin: 10,
//     //       //   padding: 110,
//     //       //   flexGrow: 1,
//     //     },
//     //   });

//     const styles = StyleSheet.create({
//       page: {
//         backgroundColor: "#fff",
//         color: "rgb(0,0,0)",
//       },
//       section: {
//         margin: 10,
//         padding: 10,
//       },
//       viewer: {
//         width: "210mm",
//         height: "297mm",
//         //   width: window.innerWidth, //the pdf viewer will take up all of the width and height
//         //   height: window.innerHeight,
//       },
//     });

//     return (
//       <>
//         <img
//           src={invoice}
//           style={{
//             position: "absolute",
//             filter: "invert(1)",
//             width: "210mm",
//             opacity: "0.3",
//             zIndex: 1000,
//           }}
//         ></img>

//         <PDFViewer style={styles.viewer}>
//           {/* Start of the document*/}
//           <Document>
//             {/*render a single page*/}
//             <Page size="A4" style={styles.page}>
//               <View style={styles.section}>
//                 <Image src={logo} />
//               </View>
//             </Page>
//           </Document>
//         </PDFViewer>

//         {/* <Document style={styles.document}>
//           <Page size="A4" style={styles.page}>
//             <View style={styles.section}>
//               <Text>Section #1</Text>
//             </View>
//           </Page>
//         </Document> */}

//         {/* <div className="invoiceContainer">
//           <div className="headerContainer">
//             <img
//               src={logo}
//               style={{ width: 100, height: 100, marginLeft: 20 }}
//             ></img>
//             <div className="headerInfoContainer">
//               <span style={{ fontSize: 13 }}>Morrissey Media</span>
//               <span style={headerInfoSubStyle}>0481308553</span>
//               <span style={headerInfoSubStyle}>17/261 King Georges Road</span>
//               <span style={headerInfoSubStyle}>Roselands, 2196, Australia</span>
//               <span style={headerInfoSubStyle}>ABN 40551956933</span>
//             </div>
//           </div>
//           <div className="topInfoContainer">
//             <div className="topLeftContainer">
//               <span style={{ fontSize: 20, fontWeight: 800 }}>Invoice</span>
//               <span style={{ fontSize: 11, fontWeight: 800, marginTop: 9 }}>
//                 Date of Issue:&nbsp;&nbsp;&nbsp;&nbsp;01/01/2000
//               </span>
//               <span style={{ fontSize: 11, fontWeight: 800, marginTop: 5 }}>
//                 Due
//                 Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01/01/2000
//               </span>
//               <span style={{ fontSize: 11, fontWeight: 800, marginTop: 21 }}>
//                 Billed To:
//               </span>
//               <span className="topLeftSub">Name</span>
//               <span className="topLeftSub">Email</span>
//               <span className="topLeftSub">Company Name</span>
//               <span className="topLeftSub">Street Address</span>
//               <span className="topLeftSub">City, State, Zip</span>
//             </div>
//             <div className="topRightContainer">
//               <span style={{ fontSize: 24, fontWeight: 800 }}>Invoice #</span>
//               <span style={{ fontSize: 14, fontWeight: 800, marginTop: 10 }}>
//                 20000
//               </span>
//             </div>
//           </div>
//           <hr style={{ marginTop: 13 }} />
//         </div> */}
//       </>
//     );
//   };

//   export default Invoice;
